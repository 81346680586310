import React from "react"
import { Modal, Nav, Navbar } from "react-bootstrap"
import { useLocation } from "react-router"
import { useProfileDialogStore } from "../../../store/profileDialogStore"
import { userSessionStore } from "../../../store/userSessionStore"
import { CheckGenericPermission } from "../../permissions/CheckPermision"
import { ToastPopup } from "../../toast/Toast"
import { clearHash } from "../../utlity/GenricFunctions"
import ProfilePaymentMethodsTab from "../payments/ProfilePaymentMethods.tab"
import ProfileAddressesTab from "./ProfileAddresses.tab"
import ProfileInfoTab from "./ProfileInfo.tab"
import ProfilePricingTab from "./ProfilePricing.tab"

const ProfileDialog = () => {
	const profileStore = useProfileDialogStore()
	const clearHashClear = clearHash()
	return (
		<React.Fragment>
			<Modal
				show={profileStore.modalOpen}
				size="xl"
				backdrop="static"
				onHide={() => {
					profileStore.setModalOpen( false ), clearHashClear()
				}}
			>
				<ProfileNavbar />
				<ProfileDailogImp />
			</Modal>
		</React.Fragment>
	)
}

const ProfileDailogImp = () => {
	return (
		<React.Fragment>
			<Modal.Body className="p-0">
				<section className="p-2">
					<TabShowCase />
				</section>
			</Modal.Body>
			<ToastPopup />
		</React.Fragment>
	)
}

const TabShowCase = () => {
	const location = useLocation()
	switch ( location.hash ) {
		case "#info":
			return <ProfileInfoTab />
		case "#Offices":
			return <ProfileAddressesTab />
		case "#Pricing":
			return <ProfilePricingTab />
		case "#Paymentmethods":
			return <ProfilePaymentMethodsTab />
		case "#Invoicing":
			return <h5>Upcomming...</h5>
		default:
			return <ProfileInfoTab />
	}
}

const ProfileNavbar = () => {
	const sessionUser = userSessionStore( ( state: any ) => state.user )
	return (
		<Navbar style={{ backgroundColor: "#d9d9d9" }} as={Modal.Header} closeButton>
			<Nav
				defaultActiveKey={"#info"}
				className="w-100"
				style={{ marginTop: "5px" }}
				onClick={() => {
					document.getElementById( "submitInfo" )?.click()
				}}
			>
				<Nav.Link defaultChecked className="nav-link-opposite" eventKey={"#info"} href="#info">
					Info
				</Nav.Link>
				{sessionUser?.isDoctor !== false && (
					<React.Fragment>
						<CheckGenericPermission genericLabel={"Office Tab"}>
							<Nav.Link className="nav-link-opposite" eventKey={"#Offices"} href="#Offices">
								Offices
							</Nav.Link>
						</CheckGenericPermission>
						<CheckGenericPermission genericLabel={"Pricing Tab"}>
							<Nav.Link className="nav-link-opposite" eventKey={"#Pricing"} href="#Pricing">
								Pricing
							</Nav.Link>
						</CheckGenericPermission>
						<CheckGenericPermission genericLabel={"Invoicing Tab"}>
							<Nav.Link className="nav-link-opposite" eventKey={"#Invoicing"} href="#Invoicing">
								Invoicing
							</Nav.Link>
						</CheckGenericPermission>
						<CheckGenericPermission genericLabel={"Payment Tab"}>
							<Nav.Link
								className="nav-link-opposite"
								eventKey={"#Paymentmethods"}
								href="#Paymentmethods"
							>
								Payment methods
							</Nav.Link>
						</CheckGenericPermission>
					</React.Fragment>
				)}
			</Nav>
		</Navbar>
	)
}
export default ProfileDialog
