import { Params, useParams, useNavigate } from "react-router-dom"
import generatePDF from "react-to-pdf"
import { useServicePricingsfetchDetails } from "../../services/CelebService.Service"
import { getPatientByIdQuery } from "../../services/CelebTreatment.Service"
import "./receiptGenerator.css"
import { Button, Container, Navbar } from "react-bootstrap"

const ReceiptGenerator = () => {
	return (
		<>
			<NavbarReciept />
			<div id="content-id" className="d-flex">
				<Receipt />
			</div>
		</>
	)
}

const Receipt = () => {
	const paramsData: Readonly<Params<string>> = useParams()
	const { data: defaultValuesEdit } = getPatientByIdQuery(paramsData.id ?? "null")
	const { data: data } = useServicePricingsfetchDetails(paramsData.id)

	return (
		<Container style={{ border: "2px solid #d9d9d9", padding: "5rem", marginTop: "2rem" }}>
			<div style={{ display: "flex", marginBottom: "2%", justifyContent: "space-between" }}>
				<div>
					<h1>Celebrace </h1>
					<div>4901 Keller Springs Rd</div>
					<div> #102 Addison, TX 75001</div>
					<span>Email : 
						<a href="http://support@celebrace.com">support@celebrace.com</a>{" "}
					</span>
					<div>Phone :3124791449</div>
				</div>{" "}
				<div>
					<h1 className="text-end">RECEIPT</h1>{" "}
				</div>
			</div>
			<hr />
			<div className="mt-5">
				<div className="d-flex justify-content-between">
					<div>
						<strong className="mb-3">Bill To:</strong>
						<div>{defaultValuesEdit.doctor.firstName + defaultValuesEdit.doctor.lastName}</div>
						<div>
							{defaultValuesEdit.treatmentPlanDoctorAddress.addressLine1 +
								", " +
								defaultValuesEdit.treatmentPlanDoctorAddress.addressLine2}
						</div>
						<div>
							{defaultValuesEdit.treatmentPlanDoctorAddress.city +
								", " +
								defaultValuesEdit.treatmentPlanDoctorAddress.state +
								", " +
								defaultValuesEdit.treatmentPlanDoctorAddress.pincode}
						</div>
					</div>

					<div>
						<img src={`${process.env.PUBLIC_URL}/celebLogo.png`} height={100} width={100}></img>
					</div>
					<div>
						<strong>Ship To</strong>
						<div>{defaultValuesEdit.treatmentPlanDoctorAddress.name}</div>
						<div>
							{defaultValuesEdit.treatmentPlanDoctorAddress.addressLine1 +
								", " +
								defaultValuesEdit.treatmentPlanDoctorAddress.addressLine2}
						</div>
						<div>
							{defaultValuesEdit.treatmentPlanDoctorAddress.city +
								", " +
								defaultValuesEdit.treatmentPlanDoctorAddress.state +
								", " +
								defaultValuesEdit.treatmentPlanDoctorAddress.pincode}
						</div>
					</div>
				</div>
				<table style={{ width: "100%", padding: "2px" }} className="mt-4">
					<thead>
						<tr className="myRow">
							<th className="center myHead myRow">QTY</th>
							<th className="center myHead myRow">Description</th>
							<th className="center myHead myRow">Unit Price</th>
							<th className="center myHead myRow">Amount</th>
						</tr>
					</thead>
					<tbody>
						<tr className="myRow">
							<td className="center">1</td>
							<td className="left">Upper Braces / Lowe Braces / Booth</td>
							<td className="myCol">${data?.amount}</td>
							<td>${data?.amount}</td>
						</tr>
						<tr>
							<td className="center"></td>
							<td className="left"></td>
							<td className="myRow">SubTotal</td>
							<td className="myRow">${data?.amount}</td>
						</tr>
						<tr>
							<td className="center"></td>
							<td className="left"></td>
							<td className="myRow">Sales Tax 6.25%</td>
							<td className="myRow">
								${" "}
								{(Number(data?.amount) * 100) % 6.25 > 0
									? (Number(data?.amount) * 100) % 6.25
									: "0"}
							</td>
						</tr>
						<tr>
							<td className="center"></td>
							<td className="left"></td>
							<td className="myRow">
								<b>Total</b>
							</td>
							<td className="myRow">
								{" "}
								<b>
									${" "}
									{((Number(data?.amount) * 100) % 6.25) + Number(data?.amount) > 0
										? ((Number(data?.amount) * 100) % 6.25) + Number(data?.amount)
										: "0"}
								</b>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</Container>
	)
}

const NavbarReciept = () => {
	const getTargetElement = () => document.getElementById("content-id")
	const navigate = useNavigate()

	return (
		<Navbar className="bg-body-tertiary justify-content-between" style={{ width: "100%" }}>
			<div>
				<Button onClick={() => navigate(-1)}>Go Back</Button>
			</div>

			<div>
				<Button onClick={() => generatePDF(getTargetElement)}>Download</Button>
			</div>
		</Navbar>
	)
}

export default ReceiptGenerator
