import { faUser } from "@fortawesome/free-regular-svg-icons"
import { FontAwesomeIcon as FA } from "@fortawesome/react-fontawesome"
import { useEffect, useState, useTransition } from "react"
import { Col, Image, Spinner } from "react-bootstrap"
import "../../assets/styles/components/navbar.css"
import { constants } from "../../services/CelebApiConstants"
import { useLogoutMutation } from "../../services/CelebUser.Service"
import { useAccountsDialogStore } from "../../store/accountsDialogStore"
import { useProfileDialogStore } from "../../store/profileDialogStore"
import { useSalesModalStore } from "../../store/salesDialogStore"
import { useServiceDialogStore } from "../../store/serviceDialogStore"
import { userSessionStore } from "../../store/userSessionStore"
import { CheckGenericPermission } from "../permissions/CheckPermision"
const ProfileNavbar = () => {
	const profileStore = useProfileDialogStore()
	const sessionUser = userSessionStore( ( state: any ) => state.user )
	const setSession = userSessionStore( ( state: any ) => state.setSession )
	const [ isTransitioning, startTransition ] = useTransition()
	const [ profilephoto, setprofilephoto ] = useState( "" )
	const { mutateAsync: logout } = useLogoutMutation()

	useEffect( () => {
		const userDataStr: string | null = localStorage.getItem( "user" )
		const userInLocalStorage = JSON.parse( userDataStr! )
		setprofilephoto( userInLocalStorage?.profilePictureUri )
		if ( !sessionUser ) {
			const token = localStorage.getItem( "token" )
			if ( token ) {
				setprofilephoto( userInLocalStorage?.profilePictureUri )
				setSession( userInLocalStorage )
			}
		}
	}, [ sessionUser, setSession ] )

	const showServicesDialog = () => {
		startTransition( () => useServiceDialogStore.getState().setListOpen( true ) )
	}

	const showAccountsDialog = () => {
		startTransition( () => useAccountsDialogStore.getState().setListOpen( true ) )
	}

	const showSalesDialog = () => {
		startTransition( () => useSalesModalStore.getState().setModalOpen( true ) )
	}

	const onLogout = async () => {
		await logout()
		window.localStorage.clear()
		window.location.reload()
	}

	return (
		<>
			<div style={{ width: "-webkit-fill-available", backgroundColor: "transperent !important;" }}>
				<div
					className="collapse navbar-collapse flex-column ml-lg-0 ml-3 celeb-top-navbar"
					id="navbarCollapse"
				>
					<div style={{ display: "flex" }}>
						<Col>
							<ul className="navbar-nav celeb-navbar-username">
								<li className="nav-item celeb-menu-item" style={{ width: "max-content" }}>
									<a
										className="nav-link py-1 pr-3"
										href="#"
									>{`${sessionUser?.isDoctor ? "Dr. " : ""} ${
										sessionUser?.firstName
									} ${sessionUser?.lastName}`}</a>
								</li>
							</ul>
							<ul className="navbar-nav celeb-navbar-lower flex-row mb-2">
								<>
									<CheckGenericPermission genericLabel={"Menu Accounts"}>
										<li
											className={"navbarOptions nav-item celeb-menu-item active"}
											onClick={showAccountsDialog}
										>
											{isTransitioning && <Spinner animation="border" role="status" size="sm" />}
											Accounts
										</li>
									</CheckGenericPermission>
								</>
								<>
									<CheckGenericPermission genericLabel={"Menu Services"}>
										<li
											className="navbarOptions nav-item celeb-menu-item"
											onClick={showServicesDialog}
										>
											Services
										</li>
									</CheckGenericPermission>
								</>
								<>
									<CheckGenericPermission genericLabel={"Menu Sales"}>
										<li
											className="navbarOptions nav-item celeb-menu-item"
											onClick={showSalesDialog}
										>
											Sales
										</li>
									</CheckGenericPermission>
								</>
								<>
									<CheckGenericPermission genericLabel={"Menu Financials"}>
										<li className="navbarOptions nav-item celeb-menu-item">Financials</li>
									</CheckGenericPermission>
								</>
								<>
									<CheckGenericPermission genericLabel={"Menu Data"}>
										<li className="navbarOptions nav-item celeb-menu-item">Data</li>
									</CheckGenericPermission>
								</>
								<CheckGenericPermission genericLabel={"Menu Profile"}>
									<li className="navbarOptions nav-item celeb-menu-item">
										<a
											className="text-decoration-none text-black"
											href={"#info"}
											onClick={() => profileStore.setModalOpen( true )}
										>
											Profile
										</a>
									</li>
								</CheckGenericPermission>
								<li className="navbarOptions nav-item celeb-menu-item" onClick={onLogout}>
									Logout
								</li>
							</ul>
						</Col>
						<Col className="d-flex align-items-center">
							<div
								style={{
									width: "45px",
									height: "45px",
									overflow: "hidden",
									borderRadius: "50%",
									backgroundColor: "#CECECE",
									display: "grid",
									alignItems: "center",
								}}
							>
								{!profilephoto ? (
									<FA icon={faUser} style={{ width: "-webkit-fill-available" }} size="lg" />
								) : (
									<Image
										src={constants.IMAGEURL + profilephoto}
										roundedCircle
										className="celeb-navbar-user-thumb"
										style={{ objectFit: "cover", width: "100%", height: "100%" }}
									/>
								)}
							</div>
						</Col>
					</div>
				</div>
			</div>
		</>
	)
}

export default ProfileNavbar
