import { createBrowserRouter, Navigate } from "react-router-dom"
import AppCommon from "../AppCommon"
import ReceiptGenerator from "../components/receipt/receiptCreater"
import Dashboard from "../pages/Admin/Management/Dashboard/Dashboard"
import SuperAdminLandingPage from "../pages/Admin/Management/LandingPage/LandingPage"
import InvalidLink from "../pages/auth/InvalidLink"
import Login from "../pages/auth/Login"
import ResetAuth from "../pages/auth/ResetAuth"
import ResetPassword from "../pages/auth/resetPassword.page"
import Unathorized from "../pages/CustomError/Unathorized"
import { DoctorDashboard, DoctorLandingPage } from "../pages/doctor/exporter"
import { OperatorDashboard, OperatorlandingPage } from "../pages/operator/exporter"
import { OperatorLandingPage, SalesDashboard } from "../pages/sales/exporter"
import ErrorBoundaryPage from "./errorBoundary"
import RouterAuth from "./RouterAuth"

const router = createBrowserRouter( [
	{
		path: "/",
		element: <Navigate to="/login" />,
	},
	{
		path: "/login",
		element: <Login />,
	},
	{
		path: "/forgotPassword",
		element: (
			<ResetAuth>
				<ResetPassword />
			</ResetAuth>
		),
		errorElement: <InvalidLink />,
	},

	{
		path: "/managment",
		errorElement: <ErrorBoundaryPage />,
		element: (
			<RouterAuth>
				<Dashboard />
				<AppCommon />
			</RouterAuth>
		),
		children: [
			{
				path: ":id",
				element: <SuperAdminLandingPage />,
			},
		],
	},
	{
		path: "/doctor",
		errorElement: <ErrorBoundaryPage />,
		element: (
			<RouterAuth>
				<DoctorDashboard />
				<AppCommon />
			</RouterAuth>
		),
		children: [
			{
				path: ":id",
				element: <DoctorLandingPage />,
			},
		],
	},
	{
		path: "/sales",
		errorElement: <ErrorBoundaryPage />,
		element: (
			<RouterAuth>
				<SalesDashboard />
				<AppCommon />
			</RouterAuth>
		),
		children: [
			{
				path: ":id",
				element: <OperatorLandingPage />,
			},
		],
	},
	{
		path: "/operator",
		errorElement: <ErrorBoundaryPage />,
		element: (
			<RouterAuth>
				<OperatorDashboard />
				<AppCommon />
			</RouterAuth>
		),
		children: [
			{
				path: ":id",
				element: <OperatorlandingPage />,
			},
		],
	},
	{
		path: "/receipt/:id",
		errorElement: <ErrorBoundaryPage />,
		element: <ReceiptGenerator />,
	},

	{
		path: "/Error/:custom",
		element: <Unathorized />,
	},
] )

export default router
