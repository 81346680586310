import "../../assets/styles/components/buttons/celebButtonToothNumber.css"
import { toothCondition } from "../../store/toothSelectionStore"

export interface CelebButtonTootheNumberProps {
	tootheNumber: number
	active?: toothCondition | boolean
	disabled?: toothCondition | boolean
	isNoTreatmentSelected?: boolean | undefined
}

const CelebButtonTootheNumber = ( props: CelebButtonTootheNumberProps ) => {
	return (
		<span
			className="d-inline-block tooltip-teeth"
			tabIndex={0}
			data-toggle="tooltip"
			title={props.isNoTreatmentSelected ? "Disabled" : "select"}
		>
			<div
				className={
					"button-tooth-number " +
					( props.isNoTreatmentSelected
						? "noTreatmentSelected"
						: props.active == "disabled"
							? "disabled"
							: props.active == "selected"
								? "active "
								: "" )
				}
			>
				{props.tootheNumber}
			</div>
		</span>
	)
}

export default CelebButtonTootheNumber
