import React from "react"
import { Badge, Col, Row, Table } from "react-bootstrap"
import Moment from "react-moment"
import {
	ResponseTypeRole,
	useDeleteRoleMutation,
	useListRolesQuery,
} from "../../../services/CelebRole.Service"
import { AccountDeleteDialog, DeleteDialog } from "../DeleteDialog"

import { faPencil, faTrash } from "@fortawesome/free-solid-svg-icons"
import { Link } from "react-router-dom"
import { useAccountsDialogStore } from "../../../store/accountsDialogStore"
import SearchInput from "../../form/SearchInput"

import Pagination from "rc-pagination"
import { constants } from "../../../services/CelebApiConstants"
import { CheckGenericPermission } from "../../permissions/CheckPermision"
import { EditTrash } from "../../utlity/EditTrash"
import AccountTypesAddDialog from "./AccountTypesAdd.dialog"
import AccountTypesEditDialog from "./AccountTypesEdit.dialog"

export const AccountTypeTabList = () => {
	const [ currentPage, setCurrentPage ] = React.useState( 1 )
	const pageOffset = ( currentPage - 1 ) * constants.PAGE_COUNT
	const { setTypesCreateOpen, setTypesEditOpen, typesEditOpen } = useAccountsDialogStore()
	const [ query, setQuery ] = React.useState( "" )
	const {
		data: { data: roles, total },
	} = useListRolesQuery( { pageOffset, query: query, limit: constants.PAGE_COUNT } )
	const deleteDialogRef = React.useRef<AccountDeleteDialog>( null )
	const { mutate } = useDeleteRoleMutation()
	const handlePagination = ( pageNumber: number ) => {
		setCurrentPage( pageNumber )
	}

	return (
		<Col>
			<div className="d-flex justify-content-between my-1">
				<span className="d-flex">
					<SearchInput callback={( e ) => setQuery( e )} placeholder="Search Account" />
					<CheckGenericPermission genericLabel={"Account Type List"}>
						<Badge bg="secondary" className="mx-2 d-grid align-content-center">
							{total} {total < 2 ? 'account' : 'accounts'}
						</Badge>
					</CheckGenericPermission>
				</span>

				<CheckGenericPermission genericLabel={"Account Type Create"}>
					<Link
						to="#genericpermissions"
						className="btn btn-primary"
						onClick={() => setTypesCreateOpen( true )}
					>
						Add New Account Type
					</Link>
				</CheckGenericPermission>
			</div>

			<CheckGenericPermission genericLabel={"Account Type List"}>
				<Row>
					<Col>
						<Table bordered striped hover className="mt-4">
							<thead>
								<tr>
									<th>Account type</th>
									<th>Status</th>
									<th>Created On</th>
									<th>Action</th>
								</tr>
							</thead>
							<tbody>
								{roles.map( ( role: ResponseTypeRole ) => {
									return (
										<tr key={role.id}>
											<td>{role.name}</td>
											<td>{role.status ? "Active" : "Inactive"}</td>
											<td>
												<Moment format="MM-DD-YYYY">{role.createdAt}</Moment>
											</td>
											<td>
												<CheckGenericPermission genericLabel={"Account Type Edit"}>
													<EditTrash
														editIcon={{ icon: faPencil, color: "#8faadc" }}
														trashIcon={{ icon: faTrash, color: "#d9d9d9" }}
														deleteCallBack={() => deleteDialogRef.current?.showDialog( role )}
														editCallback={() => setTypesEditOpen( role.id )}
													/>
												</CheckGenericPermission>
											</td>
										</tr>
									)
								} )}
							</tbody>
						</Table>
					</Col>
					<Pagination
						total={total}
						pageSize={constants.PAGE_COUNT}
						onChange={handlePagination}
						align={"center"}
					/>
				</Row>
			</CheckGenericPermission>
			<DeleteDialog
				ref={deleteDialogRef}
				onDeleteOkCallback={( id ) => mutate( id )}
				deletingEntityName="name"
			/>
			<AccountTypesAddDialog />
			{typesEditOpen != undefined && <AccountTypesEditDialog />}
		</Col>
	)
}
