import { Form, FormControlProps } from "react-bootstrap"
import { get, useFormContext } from "react-hook-form"
import { PatientSchemaType } from "../../../services/CelebPatient.Service"

export const PatientInputForm = ( { name, label, required, ...props }: ServiceInputProps ) => {
	const { register, formState: errors, control } = useFormContext<PatientSchemaType>()

	return (
		<Form.Group>
			<Form.Label htmlFor={name}>
				{label}
				{required && <span style={{ color: "red" }}>*</span>}
			</Form.Label>
			<Form.Control
				id={name}
				{...props}
				isInvalid={required ? get( errors.errors, name ) : false}
				{...register( name )}
			/>
			{required && (
				<p className="text-danger">
					{get( errors.errors[ name ], name )} {errors.errors[ name ]?.message}
				</p>
			)}
		</Form.Group>
	)
}

export const PatientSelectForm = ( { name, label, required, ...props }: ServiceInputProps ) => {
	const { register, formState: errors } = useFormContext<PatientSchemaType>()
	return (
		<Form.Group>
			<Form.Label>
				{label}
				{required && <span style={{ color: "red" }}>*</span>}
			</Form.Label>
			<Form.Select {...register( name )} isInvalid={required ? get( errors.errors, name ) : false}>
				{props.children}
			</Form.Select>
			<p className="text-danger">
				{get( errors.errors[ name ], name )} {errors.errors[ name ]?.message}
			</p>
		</Form.Group>
	)
}

export const PatientOtherForm = ( { name, label, required, ...props }: ServiceInputProps ) => {
	const { register, formState: errors } = useFormContext<PatientSchemaType>()
	return (
		<Form.Group>
			<Form.Label>
				{label}
				{required && <span style={{ color: "red" }}>*</span>}
			</Form.Label>
			{props.children}
			<p className="text-danger">
				{get( errors.errors[ name ], name )} {errors.errors[ name ]?.message}
			</p>
		</Form.Group>
	)
}

export const PatientCheckForm = ( { name, label, required, ...props }: ServiceInputProps ) => {
	const { register, formState: errors } = useFormContext<PatientSchemaType>()
	return (
		<Form.Group>
			<Form.Label>
				{label}
				{required && <span style={{ color: "red" }}>*</span>}
			</Form.Label>
			<Form.Check {...register( name )}></Form.Check>
			<p className="text-danger">
				{get( errors.errors[ name ], name )} {errors.errors[ name ]?.message}
			</p>
		</Form.Group>
	)
}

export type ServiceInputProps = Omit<FormControlProps, "name"> & {
	name: keyof PatientSchemaType
	label?: string
	required?: boolean
	error?: string
}

export default PatientInputForm
