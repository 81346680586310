import { faPencil, faTrash } from "@fortawesome/free-solid-svg-icons"
import { zodResolver } from "@hookform/resolvers/zod"
import React, { Suspense, useEffect } from "react"
import { Alert, Button, Form, Modal, Nav, Navbar, Spinner } from "react-bootstrap"
import { FieldErrors, FormProvider, useForm } from "react-hook-form"
import CreatableSelect from "react-select/creatable"
import "../../../assets/styles/components/celebAddServiceDialog.css"
import LoadingOverlay from "../../../assets/styles/components/loader/loadingOverlay"
import { constants } from "../../../services/CelebApiConstants"
import {
	ServiceSchemaForm,
	useCategoryfetchDetails,
	useCategoryfetchDetailsMutate,
	useEditServiceMutation,
} from "../../../services/CelebService.Service"
import { useServiceDialogStore } from "../../../store/serviceDialogStore"
import { TaskCategory } from "../../../utils/appConstants"
import { EditTrash } from "../../utlity/EditTrash"
import { CreatableSelectEdit, ServiceFormInput, ServiceFormType } from "./ServiceFormFields"
import { ServicePricingSetter, uploadLogo } from "./ServicesAdd.dialog"
import ServiceTaskBox from "./ServiceTaskForm"

const ServicesEditdialog = () => {
	const serviceStore = useServiceDialogStore()
	const modalShowCase = serviceStore.editOpen === null ? false : true
	const serviceId = serviceStore.editOpen

	const clearStore = () => {
		serviceStore.setEditOpen( null )
		serviceStore.updateserviceConsumerCategories( [] )
		serviceStore.updateserviceProviderCategories( [] )
		serviceStore.categories
		serviceStore.resetTaskCategories()
	}
	const { mutate: serviceCall, data: serviceData } = useCategoryfetchDetailsMutate()
	useEffect( () => {
		serviceCall( serviceId! )
	}, [] )

	return (
		<React.Fragment>
			<Modal show={modalShowCase} size="xl" backdrop="static" onHide={clearStore}>
				<Navbar as={Modal.Header} closeButton>
					<Nav variant="tabs">
						<Nav.Link active className="mt-1">
							Edit Service
						</Nav.Link>
					</Nav>
				</Navbar>
				{serviceData ? <ServicesEditImpl serviceData={serviceData} /> : <Spinner />}
			</Modal>
		</React.Fragment>
	)
}

const ServicesEditImpl = ( { serviceData }: { serviceData: { services: any; tasks: any } } ) => {
	const serviceStore = useServiceDialogStore()
	const serviceId = serviceStore.editOpen
	const [ logo, setLogo ] = React.useState<FileList | null>()
	const { data: categoryData } = useCategoryfetchDetails( serviceId! )
	const { mutate: serviceEditSave } = useEditServiceMutation()

	const methods = useForm<ServiceFormType>( {
		resolver: zodResolver( ServiceSchemaForm ),
		mode: "all",
		defaultValues: {
			...serviceData?.services,
			status: serviceData?.services?.isActive,
		},
	} )
	const getCategory: { [key: string]: ReactSelectCreatableCustom[] } = {
		provide: serviceStore.serviceProviderCategories,
		consume: serviceStore.serviceConsumerCategories,
	}

	const createCategory: { [key: string]: serviceStoreCreateCallback } = {
		provide: serviceStore.setServiceProviderCategories,
		consume: serviceStore.setServiceConsumerCategories,
	}

	const updateCategory: { [key: string]: serviceStoreCallback } = {
		provide: serviceStore.updateserviceProviderCategories,
		consume: serviceStore.updateserviceConsumerCategories,
	}

	const [ editValue, setEditValue ] = React.useState<ReactSelectCreatableCustom>( {
		value: "",
		label: "",
		type: "",
		show: "",
	} )

	const createOption = (
		labelName: string,
		categoryFor: TaskCategory.PROVIDER | TaskCategory.OTHERS,
	) => {
		const id = crypto.randomUUID()
		const dataToCheck = getCategory[ categoryFor ].filter(
			( x: ReactSelectCreatableCustom ) => x.label === labelName,
		)
		if ( dataToCheck.length >= 1 ) return
		createCategory[ categoryFor ]( {
			value: id,
			type: categoryFor,
			label: labelName,
		} )
	}

	if ( serviceStore.serviceProviderCategories.length == 0 ) {
		categoryData.forEach( ( x: categoryResponseType ) => {
			const dataToCheck = getCategory[ x.type ].filter(
				( y: ReactSelectCreatableCustom ) => y.label === x.name,
			)
			if ( dataToCheck.length >= 1 ) return
			createCategory[ x.type ]( {
				value: x.id,
				type: x.type,
				label: x.name,
			} )
		} )
	}

	const categoryToDelete = () => {
		if ( getCategory[ editValue.type ] == undefined ) return null
		const getTaskCategoryToDelete: { [key: string]: "consumerCategoryId" | "providerCategoryId" } =
			{
				provide: "providerCategoryId",
				consume: "consumerCategoryId",
			}
		const category = getTaskCategoryToDelete[ editValue.type ]

		const deleteClearance = methods
			.getValues( "tasks" )
			.filter( ( x ) => x[ category ] === editValue.value )

		if ( deleteClearance.length >= 1 )
			return methods.setError( "tasks", {
				message: "Please update the task first, task is dependant on the this category",
			} )
		const dataToDelete = getCategory[ editValue.type ].filter(
			( x: ReactSelectCreatableCustom ) => x.value != editValue.value,
		)

		updateCategory[ editValue.type ]( dataToDelete )
		setEditValue( { ...editValue, value: "", label: "", type: "" } )
	}

	const categoryToEdit = () => {
		const dataToEdit = getCategory[ editValue.type ].filter(
			( x: ReactSelectCreatableCustom ) => x.value != editValue.value,
		)
		editValue.edited = true
		updateCategory[ editValue.type ]( [ ...dataToEdit, editValue ] )
		setEditValue( { value: "", label: "", type: "" } )
	}

	const editCallback = () => {
		editValue.value.length >= 2 ? setEditValue( { ...editValue, show: editValue.type } ) : null
	}

	const saveEditedService = async ( value: ServiceFormType ) => {
		const logoUrl = logo != null ? await uploadLogo( logo ) : value.logo
		const formObj = {
			...value,
			categories: [
				...serviceStore.serviceConsumerCategories,
				...serviceStore.serviceProviderCategories,
			],
			logo: logoUrl,
		}
		serviceEditSave( { serviceId, requestPayload: formObj } )
		serviceStore.setEditOpen( null )
		methods.reset( {
			name: "",
		} )
		serviceStore.resetTaskCategories()
	}

	const onError = ( error: FieldErrors ) => {
		console.error( error )
	}

	return (
		<React.Suspense fallback={<LoadingOverlay />}>
			<Modal.Body>
				<FormProvider {...methods}>
					{serviceData?.services && (
						<Form onSubmit={methods.handleSubmit( saveEditedService, onError )}>
							<span className="d-inline-flex justify-content-evenly w-100">
								<div className="justify-content-center">
									<Form.Label>Service ID</Form.Label>
									<br />
									<Form.Label>{serviceData?.services.entityId}</Form.Label>
								</div>
								<ServiceFormInput label="Service Name" name="name" />
								<div>
									<Form.Label>Service Logo</Form.Label>
									<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
										<Form.Control
											id="logo-upload"
											type="file"
											accept="image/*"
											onChange={( e: React.ChangeEvent<HTMLInputElement> ) => {
												setLogo( e.currentTarget?.files )
											}}
											hidden
										/>
										<label htmlFor="logo-upload">
											<img
												src={
													logo
														? URL.createObjectURL( logo[ 0 ] )
														: constants.IMAGEURL + serviceData.services.logo
												}
												style={{
													width: "2.5rem",
													height: "2.5rem",
													cursor: "pointer",
													borderRadius: "5px",
												}}
												alt="Selected Logo"
												onError={( { currentTarget } ) => {
													currentTarget.onerror = null
													currentTarget.src = constants.IMAGEURL + "building.png"
												}}
											/>
										</label>
									</div>
								</div>
								<div>
									<Form.Label>Service Status</Form.Label>
									<Form.Check
										type="checkbox"
										{...methods.register( "status" )}
										label={"Make Active"}
									/>
								</div>
								<div className="d-inline-flex align-items-center">
									<Button type="submit">
										{methods.formState.isSubmitting ? (
											<>
												<Spinner animation="border" role="status" size="sm" /> Saving..{" "}
											</>
										) : (
											"Save"
										)}
									</Button>
									<Button variant="secondary" className="mx-2">
										Cancel
									</Button>
								</div>
							</span>
							<span className="d-inline-flex justify-content-evenly w-100">
								<ServicePricingSetter
									name="Price Upper"
									formControlAccess={{
										...methods.register( "priceUpper", {
											valueAsNumber: true,
											onChange: ( e ) => {
												methods.setValue( "priceUpper", parseFloat( e.currentTarget.value ) )
											},
										} ),
									}}
								/>
								<ServicePricingSetter
									name="Price Lower"
									formControlAccess={{
										...methods.register( "priceLower", {
											valueAsNumber: true,
											onChange: ( e ) => {
												methods.setValue( "priceLower", parseFloat( e.currentTarget.value ) )
											},
										} ),
									}}
								/>
								<ServicePricingSetter
									name="Price Both"
									formControlAccess={{
										...methods.register( "priceBoth", {
											valueAsNumber: true,
											onChange: ( e ) => {
												methods.setValue( "priceBoth", parseFloat( e.currentTarget.value ) )
											},
										} ),
									}}
								/>
							</span>
							<hr />
							<section style={{ width: "100%", display: "flex" }}>
								<div style={{ width: "50%" }} className="mx-4">
									<span>
										<Form.Label>Manage Provider Categories</Form.Label>
										<span className="d-flex" style={{ width: "100%" }}>
											{editValue.show === TaskCategory.PROVIDER ? (
												<div style={{ width: "100%" }}>
													<CreatableSelectEdit
														value={editValue.label}
														onChange={( e ) => {
															setEditValue( { ...editValue, label: e.currentTarget.value } )
														}}
														autoFocus
														editCallback={categoryToEdit}
														closeCallback={() => setEditValue( { value: "", label: "", type: "" } )}
													/>
												</div>
											) : (
												<section style={{ width: "100%" }} className="d-flex">
													<div style={{ width: "100%" }}>
														<CreatableSelect
															isClearable
															options={serviceStore.serviceProviderCategories}
															onCreateOption={( e ) => createOption( e, TaskCategory.PROVIDER )}
															value={
																editValue.type == TaskCategory.PROVIDER
																	? editValue
																	: { value: "", label: "", type: "" }
															}
															onChange={( e ) => {
																setEditValue( {
																	value: e?.value + "",
																	label: e?.label + "",
																	type: e?.type + "",
																} )
															}}
														/>
													</div>
													<EditTrash
														editIcon={{
															color: editValue.type === "provider" ? "#8faadc" : "#d9d4d4",
															icon: faPencil,
															style: { fontSize: "1.5rem" },
														}}
														trashIcon={{
															color: editValue.type === "provider" ? "#5c636a" : "#d9d4d4",
															icon: faTrash,
															style: { fontSize: "1.5rem" },
														}}
														editCallback={editCallback}
														deleteCallBack={categoryToDelete}
													/>
												</section>
											)}
										</span>
									</span>
								</div>
								<span className="vr"></span>
								<div style={{ width: "50%" }} className="mx-4">
									<span>
										<Form.Label>Manage Consumer Categories</Form.Label>
										<span className="d-flex" style={{ width: "100%" }}>
											{editValue.show === TaskCategory.OTHERS ? (
												<div style={{ width: "100%" }}>
													<CreatableSelectEdit
														value={editValue.label}
														onChange={( e ) => {
															setEditValue( { ...editValue, label: e.currentTarget.value } )
														}}
														autoFocus
														editCallback={categoryToEdit}
														closeCallback={() => setEditValue( { value: "", label: "", type: "" } )}
													/>
												</div>
											) : (
												<section style={{ width: "100%" }} className="d-flex">
													<div style={{ width: "100%" }}>
														<CreatableSelect
															isClearable
															options={serviceStore.serviceConsumerCategories}
															onCreateOption={( e ) => createOption( e, TaskCategory.OTHERS )}
															value={
																editValue.type == TaskCategory.OTHERS
																	? editValue
																	: { value: "", label: "", type: "" }
															}
															onChange={( e ) => {
																setEditValue( {
																	value: e?.value + "",
																	label: e?.label + "",
																	type: e?.type + "",
																} )
															}}
														/>
													</div>
													<EditTrash
														editIcon={{
															color: editValue.type === "consumer" ? "#8faadc" : "#d9d4d4",
															icon: faPencil,
															style: { fontSize: "1.5rem" },
														}}
														trashIcon={{
															color: editValue.type === TaskCategory.OTHERS ? "#5c636a" : "#d9d4d4",
															icon: faTrash,
															style: { fontSize: "1.5rem" },
														}}
														editCallback={editCallback}
														deleteCallBack={categoryToDelete}
													/>
												</section>
											)}
										</span>
									</span>
								</div>
							</section>
							<hr />
						</Form>
					)}
					<Alert hidden={!methods.formState.errors.tasks?.message} variant="warning">
						{methods.formState.errors.tasks?.message}
					</Alert>
					<Suspense fallback={<LoadingOverlay />}>
						<ServiceTaskBox />
					</Suspense>
				</FormProvider>
			</Modal.Body>
		</React.Suspense>
	)
}

export type ReactSelectCreatableCustom = {
	value: string
	label: string
	type: string
	edited?: boolean
	show?: TaskCategory.PROVIDER | "consumer" | string
}
type serviceStoreCreateCallback = ( next: ReactSelectCreatableCustom ) => void
type serviceStoreCallback = ( next: ReactSelectCreatableCustom[] ) => void
export interface categoryResponseType {
	id: string
	name: string
	type: string
	deleted: boolean
	deletedBy: any
	deletedOn: any
	serviceId: string
	createdAt: string
	updatedAt: string
}

export default ServicesEditdialog
