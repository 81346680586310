import { create } from "zustand"

interface LoginPageState {
	forgotPasswordDialog: boolean
	email: string
	setForgotPasswordDialog: ( next: boolean, email?: string ) => void
	reset: () => void
}

export const useLoginPageStore = create<LoginPageState>( ( set ) => ( {
	forgotPasswordDialog: false,
	email: "",
	setForgotPasswordDialog: ( next: boolean, email?: string ) =>
		set( { forgotPasswordDialog: next, email: email } ),
	reset: () =>
		set( {
			forgotPasswordDialog: false,
		} ),
} ) )
