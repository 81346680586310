import Moment from "react-moment"
import { useSidebarStore } from "../../../store/sideBarStore"
import { PatientEthenicities } from "../../../utils/appConstants"
import { CheckGenericPermission } from "../../permissions/CheckPermision"
import { infoMapper, infoMapperNames } from "../../utlity/GenricFunctions"
import "./sidebar.css"

const SideNavbarInfo = () => {
	const sidebar = useSidebarStore()
	const { patient } = sidebar.sideBarPatientData.PatientData ?? "null"
	const doctorAddress = sidebar.sideBarPatientData
	return (
		<div className="sideBarPatientInfo">
			<CheckGenericPermission genericLabel="Info : First Name">
				<div>
					<span className="label">First Name :</span>{" "}
					<span className="label-data">{patient?.firstName ?? " "}</span>
				</div>
			</CheckGenericPermission>
			<CheckGenericPermission genericLabel="Info : Last Name">
				<div>
					<span className="label">Last Name :</span>{" "}
					<span className="label-data">{patient?.lastName ?? " "}</span>
				</div>
			</CheckGenericPermission>
			<CheckGenericPermission genericLabel="Info : Birth Date">
				<div>
					<span className="label">Birth Date :</span>{" "}
					<span className="label-data">
						{patient?.dob && (
							<Moment format="MM/DD/YYYY" utc>
								{patient?.dob}
							</Moment>
						)}
					</span>
				</div>
			</CheckGenericPermission>
			<div>
				<span className="label">Gender :</span>{" "}
				<span className="label-data">
					{patient?.gender && patient?.gender.charAt( 0 ).toUpperCase() + patient?.gender.slice( 1 )}
				</span>
			</div>
			<CheckGenericPermission genericLabel="Info : Ethnicity">
				<div>
					<span className="label">Ethnicity :</span>{" "}
					<span className="label-data">{PatientEthenicities[ patient?.ethenicity ] ?? ""}</span>
				</div>
			</CheckGenericPermission>
			<div>
				<span className="label">Orthodontist :</span>{" "}
				<span className="label-data">
					{patient ? infoMapperNames( doctorAddress.PatientData.doctor, [ "id" ] ) : " "}
				</span>
			</div>
			<div>
				<span className="label">Office Name :</span>{" "}
				<span className="label-data">
					{patient ? doctorAddress.PatientData.treatmentPlanDoctorAddress?.name : " "}
				</span>
			</div>
			<CheckGenericPermission genericLabel="Info : Office Address">
				<div>
					<span className="label">Office Address :</span>{" "}
					<span className="label-data">
						{patient
							? infoMapper( doctorAddress.PatientData.treatmentPlanDoctorAddress, [ "id", "name" ] )
							: " "}
					</span>
				</div>
			</CheckGenericPermission>
		</div>
	)
}

export default SideNavbarInfo
