import { faPencil, faTrash } from "@fortawesome/free-solid-svg-icons"
import React from "react"
import { Button, Table } from "react-bootstrap"
import {
	AddressSchemaType,
	useDeleteAddressMutation,
	useListAddressQuery,
} from "../../../services/CelebDoctorAddress.Service"
import { useProfileDialogStore } from "../../../store/profileDialogStore"
import { userSessionStore } from "../../../store/userSessionStore"
import DeleteDialogModal from "../../utlity/DeleteDialog"
import { infoMapper } from "../../utlity/GenricFunctions"

import { CheckGenericPermission } from "../../permissions/CheckPermision"
import { EditTrash } from "../../utlity/EditTrash"
import ProfileAddressAddDialog from "./ProfileAddressAdd.dialog"

const ProfileAddressesTab = () => {
	return (
		<React.Suspense>
			<ProfileAddressesTabImp />
		</React.Suspense>
	)
}

const ProfileAddressesTabImp = () => {
	const { data } = useListAddressQuery()
	const addressData: AddressSchemaType[] = data.Address
	const { setdeleteDialogOpen } = userSessionStore()
	const { mutate: deleteAddress } = useDeleteAddressMutation()
	const profileStore = useProfileDialogStore()
	const handleDeleteModal = ( dataOffice: AddressSchemaType ) => {
		setdeleteDialogOpen( {
			deletingEntityLabel: dataOffice.name,
			hideShow: true,
			handleDelete: () => deleteAddressMutation( dataOffice.id ),
		} )
	}

	const deleteAddressMutation = ( id: string ) => {
		deleteAddress( id )
		setdeleteDialogOpen( {
			hideShow: false,
		} )
	}

	return (
		<React.Fragment>
			<div className="text-end">
				<CheckGenericPermission genericLabel="Add Office">
					<Button onClick={() => profileStore.setCreateOpen( true )}>Add Address</Button>
				</CheckGenericPermission>
			</div>
			<Table striped bordered hover className="my-1">
				<thead>
					<tr>
						<th>Office Name</th>
						<th>Address</th>
						<th>Action</th>
					</tr>
				</thead>
				<tbody>
					<CheckGenericPermission genericLabel="Office List">
						{addressData.map( ( office: AddressSchemaType ) => {
							return (
								<tr>
									<td>{office.name}</td>
									<td>
										{infoMapper( office, [ "id", "createdAt", "updatedAt", "doctorId", "deleted" ] )}
									</td>
									<td className="text-end">
										<CheckGenericPermission genericLabel="Office Edit">
											<EditTrash
												editIcon={{ icon: faPencil, color: "#8faadc" }}
												trashIcon={{ icon: faTrash, color: "#d9d9d9" }}
												deleteCallBack={() => handleDeleteModal( office )}
												editCallback={() => profileStore.setEditOpen( office )}
											/>
										</CheckGenericPermission>
									</td>
								</tr>
							)
						} )}
					</CheckGenericPermission>
				</tbody>
			</Table>
			<DeleteDialogModal />
			<ProfileAddressAddDialog />
		</React.Fragment>
	)
}

export default ProfileAddressesTab
