import { zodResolver } from "@hookform/resolvers/zod"
import { useState } from "react"
import { Alert, Form, Modal, Navbar, Spinner } from "react-bootstrap"
import { FormProvider, useForm, useFormContext } from "react-hook-form"
import {
	ForgotPasswordRequest,
	ForgotPasswordSchema,
	useForgotPasswordMuation,
} from "../../services/CelebAuth.Service"
import { useLoginPageStore } from "../../store/loginStore"
import { validEmail } from "../../utils/Validations"
import { ToastPopup } from "../../components/toast/Toast"
import { userSessionStore } from "../../store/userSessionStore"

const LoginForgotCredentials = () => {
	const methods = useForm<ForgotPasswordRequest>( {
		resolver: zodResolver( ForgotPasswordSchema ),
		mode: "all",
	} )

	return (
		<FormProvider {...methods}>
			<LoginForgotCredentialsImp />
		</FormProvider>
	)
}

const LoginForgotCredentialsImp = () => {
	const {
		register,
		handleSubmit,
		control,
		formState: { errors },
	} = useFormContext<ForgotPasswordRequest>()
	const { forgotPasswordDialog, email, setForgotPasswordDialog } = useLoginPageStore()
	const [ isProcessing, setIsProcessing ] = useState( false )
	const { mutateAsync: forgotPassword } = useForgotPasswordMuation()
	const { setShowToastJson } = userSessionStore()
	const onSubmit = async ( data: ForgotPasswordRequest ) => {
		setIsProcessing( true )
		try {
			const response = await forgotPassword( data )
			if ( response.data.message === "success" ) {
				setForgotPasswordDialog( false )
				setShowToastJson( {
					message: "Reset password link sent to your email",
					status: "Success",
					visible: true,
				} )
			} else {
				control.setError( "root", { message: response.data.message } )
			}
		} catch ( error ) {
			control.setError( "root", { message: "Error processing request. Please try again." } )
		} finally {
			setIsProcessing( false )
		}
	}

	return (
		<>
			<Modal
				show={forgotPasswordDialog}
				onHide={() => setForgotPasswordDialog( false )}
				backdrop="static"
			>
				<form onSubmit={handleSubmit( onSubmit )}>
					<Navbar className="h-5" as={Modal.Header} closeButton>
						Forgot Password
					</Navbar>
					<Modal.Body>
						{errors.root?.message && <Alert variant="danger">{errors.root?.message}</Alert>}
						<Form.Control
							{...register( "email", { required: true, pattern: validEmail } )}
							placeholder="Email address"
							defaultValue={email}
							className={errors.email ? "is-invalid" : ""}
						/>
						{errors.email && (
							<div className="invalid-feedback">
								{errors.email.type === "pattern" ? "Invalid email format" : "Email is required"}
							</div>
						)}
						<span className="d-flex gap-2 mt-2">
							<button
								className="btn btn-secondary"
								onClick={() => setForgotPasswordDialog( false )}
								type="button"
							>
								Cancel
							</button>
							<button className="btn btn-primary" type="submit" disabled={isProcessing}>
								{isProcessing ? (
									<Spinner animation="border" role="status" size="sm"></Spinner>
								) : (
									"Proceed"
								)}
							</button>
						</span>
					</Modal.Body>
				</form>
			</Modal>
			<ToastPopup />
		</>
	)
}

export default LoginForgotCredentials
