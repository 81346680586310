import { faPencil, faTrash } from "@fortawesome/free-solid-svg-icons"
import React, { Suspense } from "react"
import { Col, Modal, Nav, Navbar, Row, Table } from "react-bootstrap"
import { useGetRepsDoctors } from "../../../services/CelebSalesRepresentative.service"
import { useSalesModalStore } from "../../../store/salesDialogStore"
import { EditTrash } from "../../utlity/EditTrash"

const SalesAssociatedDoctor = () => {
	const { showAsscociateDoctorsModal, setShowAsscociateDoctorsModal } = useSalesModalStore()
	return (
		<Suspense>
			<Modal
				show={showAsscociateDoctorsModal.userId != ""}
				onHide={() => setShowAsscociateDoctorsModal( { userId: "" } )}
				size="xl"
				backdrop="static"
			>
				<SalesAssociatedDoctorImp />
			</Modal>
		</Suspense>
	)
}

const SalesAssociatedDoctorImp = () => {
	const salesStore = useSalesModalStore()
	const { data: listSalesRepresentatives } = useGetRepsDoctors(
		salesStore?.showAsscociateDoctorsModal?.userId,
	)

	console.log( "listSalesRepresentatives", listSalesRepresentatives.data )
	return (
		<React.Fragment>
			<Navbar style={{ backgroundColor: "#d9d9d9" }} as={Modal.Header} closeButton>
				<Nav>
					<Nav.Link className="nav-link-opposite mt-1" active={true}>
						Associated Doctor
					</Nav.Link>
				</Nav>
			</Navbar>
			<Modal.Body>
				<Row>
					<Col>
						<Table bordered striped className="mt-4" hover>
							<thead>
								<tr>
									<th>Acc ID</th>
									<th>First Name</th>
									<th>Last Name</th>
									<th>Email</th>
									<th>Status</th>
									<th>Actions</th>
								</tr>
							</thead>
							<tbody>
								{listSalesRepresentatives.data.map( ( user: any ) => {
									return (
										<tr key={user.id}>
											<td>{user.saleClientDoctor.entityId}</td>
											<td>{user.saleClientDoctor.firstName}</td>
											<td>{user.saleClientDoctor.lastName}</td>
											<td>{user.saleClientDoctor.email}</td>
											<td>{user.saleClientDoctor.status ? "Active" : " Inactive"}</td>
											<td className="text-right">
												<EditTrash
													editIcon={{ icon: faPencil, color: "#8faadc" }}
													trashIcon={{ icon: faTrash, color: "#717273" }}
													editShow={false}
													trashShow={false}
													dropdownShow={true}
													dropdownOptions={[
														{
															label: "Show Sales Details",
															cb: ( e ) => {},
														},
													]}
												/>
											</td>
										</tr>
									)
								} )}
							</tbody>
						</Table>
					</Col>
				</Row>
			</Modal.Body>
		</React.Fragment>
	)
}

export default SalesAssociatedDoctor
