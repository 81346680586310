import "../sidebar.css"

const SideNavbarInfoDummy = () => {
	return (
		<div
			className="sideBarPatientInfo"
			style={{
				color: "#34495e",
				fontSize: "2rem",
				textAlign: "center",
				padding: "30px",
				borderRadius: "10px",
			}}
		></div>
	)
}

export default SideNavbarInfoDummy
