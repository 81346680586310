import "bootstrap/dist/css/bootstrap.min.css"
import React from "react"
import ReactDOM from "react-dom/client"
import { RouterProvider } from "react-router"
import "./assets/styles/global.css"
import "./index.css"
import { QueryClientProvider } from "./providers/QueryProvider"
import reportWebVitals from "./reportWebVitals"
import router from "./router"
const root = ReactDOM.createRoot( document.getElementById( "root" ) as HTMLElement )
root.render(
	<React.StrictMode>
		<QueryClientProvider>
			<RouterProvider router={router} />
		</QueryClientProvider>
	</React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
