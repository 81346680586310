import React from "react"
import { Dropdown, DropdownButton, Form, Table } from "react-bootstrap"
import Moment from "react-moment"
import { Params, useParams } from "react-router"
import { useSearchParams } from "react-router-dom"
import { useTreatmentStatusMutation } from "../../services/CelebTreatment.Service"
import {
	TreatmentSchemaType,
	useTaskServicesQuery,
} from "../../services/CelebTreatmentTask.Service"
import { usePatientDialogStore } from "../../store/patientDailogStore"
import { useSidebarStore } from "../../store/sideBarStore"
import { useTaskCompletionDialogStore } from "../../store/taskCompletionDialogStore"
import { TreatmentStates } from "../../utils/appConstants"
import { CheckGenericPermission } from "../permissions/CheckPermision"
import { infoMapperNames } from "../utlity/GenricFunctions"
import SortButton from "../utlity/SortButton"
import CustomPagination from "../utlity/pagination-component"

const TasksTable = () => {
	return (
		<React.Suspense>
			<LandingPageImpl />
		</React.Suspense>
	)
}

const LandingPageImpl = () => {
	const paramsData: Readonly<Params<string>> = useParams()
	const [ searchParams, setSearchParams ] = useSearchParams()
	const newParams = new URLSearchParams( searchParams )
	const tasksStore = useTaskCompletionDialogStore()
	const patientStore = usePatientDialogStore()
	const [ currentPage, setCurrentPage ] = React.useState<number>( 0 )
	const [ sortingData, setSortingData ] = React.useState<string[] | null>()
	const status = searchParams.get( "status" )
	const consumerCategory = searchParams.get( "consume" )
	const provideCategory = searchParams.get( "provide" )
	const searchPatient = searchParams.get( "searchPatient" )
	const doctorIdParam = searchParams.get( "doctorId" )
	const sideBarStore = useSidebarStore()
	const { mutateAsync: updateTaskStatus } = useTreatmentStatusMutation()
	const { data: tableData } = useTaskServicesQuery( {
		path:
			status == "active" && provideCategory
				? "me"
				: provideCategory || consumerCategory || status == "AwaitingDoctor"
					? "awaiting"
					: "me",
		dataProps: {
			search: searchPatient || "",
			limit: "10",
			offset: currentPage + "",
			serviceId: paramsData.id ? paramsData.id : "",
			status: status || "active",
			...( doctorIdParam && { [ "doctorId" ]: doctorIdParam } ),
			...( consumerCategory && { [ "consumerCategory" ]: consumerCategory } ),
			...( provideCategory && { [ "providerCategory" ]: provideCategory } ),
			...( sortingData && {
				[ "sortingParams" ]: JSON.stringify( sortingData ),
			} ),
		},
	} )

	const taskStoreChange = ( isTaskChecked: boolean, task: TreatmentSchemaType ) => {
		if ( isTaskChecked ) return tasksStore.setLandingPageTasks( task )
		const updatedTask: TreatmentSchemaType[] = tasksStore.landingPageTasks.filter(
			( x: TreatmentSchemaType ) => {
				return x.id !== task.id
			},
		)
		tasksStore.updateLandingPageTasks( updatedTask )
	}

	const idMapper = ( patientID: TreatmentSchemaType ) => {
		if ( patientID.treatmentId ) {
			return patientID.treatmentId
		} else if ( patientID.treatmentPlanServiceTaskTreatment.id ) {
			const taskTreatment = patientID.treatmentPlanServiceTaskTreatment.id
			return taskTreatment
		} else {
			alert( "incorrect inferer" )
		}
	}

	const onTreatmentStateChange = async (
		patientID: TreatmentSchemaType,
		status: TreatmentStates,
	) => {
		const id = idMapper( patientID )
		id && updateTaskStatus( { treatmentId: id, status } )
	}

	const addNewQueryParam = ( patientID: TreatmentSchemaType ) => {
		const id = idMapper( patientID )
		const newParams = new URLSearchParams( searchParams )
		id && newParams.set( "PatientId", id )
		setSearchParams( newParams )
		sideBarStore.setTimelineData( patientID )
	}

	const onEdit = ( patientID: TreatmentSchemaType ) => {
		const id = idMapper( patientID )
		id && patientStore.setTypesEditOpen( id )
	}

	React.useEffect( () => {
		tableData?.treatment[ 0 ] && addNewQueryParam( tableData?.treatment[ 0 ] )
	}, [ tableData?.treatment[ 0 ], status ] )

	const handleSortChange = ( apiParams: string[], sortOrder: "asc" | "desc" | undefined ): void => {
		const updatedSortingData = sortOrder ? [ ...apiParams, sortOrder ] : undefined
		setSortingData( updatedSortingData )
	}

	const selectAllTreatments = ( e: React.MouseEvent<HTMLInputElement, MouseEvent> ) => {
		if( e.currentTarget.checked==false )return tasksStore.updateLandingPageTasks( [] )
			tableData?.treatment.forEach( ( element: TreatmentSchemaType ) => {
				taskStoreChange( e.currentTarget.checked, element )
			} )
	}
	return (
		<React.Suspense>
			<Table striped hover size="sm">
				<thead>
					<tr className="table-header">
						{status == "active" && (
							<th className="d-flex gap-1">
								Check
								{status == "active" && (
									<CheckGenericPermission genericLabel="Task Select">
										<Form.Check
										checked={tasksStore?.landingPageTasks.length>0}
											onClick={( e ) => {
												selectAllTreatments( e )
											}}
										></Form.Check>
									</CheckGenericPermission>
								)}
							</th>
						)}
						<th style={{textAlign:"start"}}>
							Patient Name{" "}
							<SortButton
								sortKey="treatmentPlanPatient#firstName"
								onSortChange={handleSortChange}
							/>
						</th>
						<th hidden={status != "active"}>
							Task
							<SortButton sortKey="treatmentPlanServiceTask#name" onSortChange={handleSortChange} />
						</th>
						<th>
							Patient ID{" "}
							<SortButton sortKey="treatmentPlanPatient#entityId" onSortChange={handleSortChange} />
						</th>
						<th>
							Submit Date <SortButton sortKey="createdAt" onSortChange={handleSortChange} />
						</th>
						<th>
							Clinic{" "}
							<SortButton
								sortKey="treatmentPlanServiceTaskTreatment#treatmentPlanDoctorAddress#name"
								onSortChange={handleSortChange}
							/>
						</th>
						<th>
							Doctor{" "}
							<SortButton sortKey="treatmentPlanDoctor#firstName" onSortChange={handleSortChange} />
						</th>
						<th>Action Buttons</th>
					</tr>
				</thead>
				<tbody>
					{tableData &&
						tableData?.treatment?.map( ( x: TreatmentSchemaType ) => {
							return (
								<tr
									className="table-data"
									data-Active={newParams.get( "PatientId" ) === idMapper( x )}
									onClick={() => {
										addNewQueryParam( x )
									}}
								>
									{status == "active" && (
										<td onClick={( event ) => event?.stopPropagation()}>
											<span className="d-flex justify-content-center gap-2">
												{status == "active" && (
													<CheckGenericPermission genericLabel="Task Select">
														<Form.Check
															checked={
																tasksStore?.landingPageTasks.find( ( z ) => z.id === x.id )
																	? true
																	: false
															}
															onClick={( e ) => {
																taskStoreChange( e.currentTarget.checked, x )
															}}
														></Form.Check>
													</CheckGenericPermission>
												)}
											</span>
										</td>
									)}

									<td style={{textAlign:"start"}}>
										<CheckGenericPermission genericLabel="Patient Name">
											{infoMapperNames( x.treatmentPlanPatient, [ "entityId" ] )}
										</CheckGenericPermission>
									</td>

									<td hidden={status != "active"}>{x?.treatmentPlanServiceTask?.name}</td>
									<td>
										<CheckGenericPermission genericLabel="Patient ID">
											{x.treatmentPlanPatient.entityId}
										</CheckGenericPermission>
									</td>
									<td>
										{" "}
										<CheckGenericPermission genericLabel="Patient Submit Date">
											{<Moment date={x.createdAt} format="MM/DD/YYYY" />}
										</CheckGenericPermission>
									</td>
									<td>
										{" "}
										<CheckGenericPermission genericLabel="Patient Clinic">
											{x.treatmentPlanServiceTaskTreatment.treatmentPlanDoctorAddress.na}
										</CheckGenericPermission>
									</td>
									<td>
										{" "}
										<CheckGenericPermission genericLabel="Patient Assigned Doctor">
											{x.treatmentPlanDoctor.firstName + " " + x.treatmentPlanDoctor.lastName}
										</CheckGenericPermission>
									</td>
									<td>
										<div className="d-flex gap-2 justify-content-center">
											<CheckGenericPermission genericLabel={"View TP"}>
												<a
													className="btn btn-primary"
													style={{ margin: "1px" }}
													target="_blank"
													href={"/editor/" + x.id + "?treatmentId=" + idMapper( x )}
												>
													View TP
												</a>
											</CheckGenericPermission>
											<DropdownButton id="dropdown-basic-button" title="">
												<CheckGenericPermission genericLabel={"Patient Replace"}>
													{status == "completed" ? (
														<Dropdown.Item
															href="#PatientInfo"
															onClick={() => patientStore.setReplaceOpen( x.treatmentId )}
														>
															Replace
														</Dropdown.Item>
													) : (
														""
													)}
												</CheckGenericPermission>
												<CheckGenericPermission genericLabel={"Patient Edit"}>
													<Dropdown.Item href="#PatientInfo" onClick={() => onEdit( x )}>
														Edit
													</Dropdown.Item>
												</CheckGenericPermission>
												<CheckGenericPermission genericLabel={"Patient Pause"}>
													<Dropdown.Item
														onClick={() => onTreatmentStateChange( x, TreatmentStates.PAUSED )}
													>
														Pause
													</Dropdown.Item>
												</CheckGenericPermission>
												<CheckGenericPermission genericLabel={"Patient Transfer"}>
													<Dropdown.Item>Transfer</Dropdown.Item>
												</CheckGenericPermission>
											</DropdownButton>
										</div>
									</td>
								</tr>
							)
						} )}
				</tbody>
			</Table>
			<CustomPagination 
			currentPage={currentPage/10} 
			total={tableData?.total} 
			onChange={( pagenumber ) => setCurrentPage( ( pagenumber ) * 10 )}/>
		</React.Suspense>
	)
}

export default TasksTable
