import { useMutation, useSuspenseQuery } from "@tanstack/react-query"
import axios, { AxiosResponse } from "axios"
import { constants } from "./CelebApiConstants"
import { CelebRequestGeneratorService } from "./CelebRequestGenerator.Service"

const getGetAssignedDoctors = async () => {
	return await axios.get<AxiosResponse<AssignedDoctorResponse[]>>( "/api/api/v1/salesclient/me" )
}

const getGetAssignedDoctorsById = async ( repsId: string ) => {
	return await axios.get<AxiosResponse<AssignedDoctorResponse[]>>(
		`/api/api/v1/salesclient/getrepsdoctor/${repsId}`,
	)
}

const getSalesRepresentatives = async ( role?: string ) => {
	let queryParam = ""
	if ( role ) {
		queryParam += `?role=${role}`
	}
	const requestUrl = `${constants.BASE_URL}${constants.API_V1}${constants.USERS}${queryParam}`
	return await axios.get( requestUrl )
}

const getSalesClientAssociactedDoctor = async ( id: string ) => {
	const requestUrl = `${constants.SALES_CLIENT}${constants.DOCTOR}/${id}`
	return CelebRequestGeneratorService.processGetRequest( requestUrl )
}
const assignSalesClient = async ( clientId: string ) => {
	return await axios.post( "/api/v1/salesclient/" + clientId )
}
const deleteSalesClient = async ( id: string ) => {
	return await axios.get( "/api/v1/salesclient/" + id )
}

export const useGetAssignedDoctors = () =>
	useSuspenseQuery( {
		queryFn: () => getGetAssignedDoctors().then( ( data ) => data.data ),
		queryKey: [ "salesRep" ],
	} )

export const useGetRepsDoctors = ( userId: string ) =>
	useSuspenseQuery( {
		queryFn: () => getGetAssignedDoctorsById( userId ).then( ( data ) => data.data ),
		queryKey: [ "salesRep" ],
	} )

export const getSalesRepresentativesApi = ( props?: { role: string } ) =>
	useSuspenseQuery( {
		queryKey: [ "list", constants.USERS, props ],
		queryFn: () =>
			getSalesRepresentatives( props?.role )
				.then( ( res ) => ( { data: res.data.data, total: res.headers[ "x-total-count" ] } ) )
				.then( ( { data, total } ) => {
					return {
						data: data,
						total: total,
					}
				} ),
	} )

export const useGetSalesClientsAssociactedToDoctor = ( id: string ) =>
	useMutation( {
		mutationFn: () => getSalesClientAssociactedDoctor( id ).then( ( data ) => data.data.data ),
		mutationKey: [ "salesRep" ],
	} )

export const useAssignSalesClient = () =>
	useMutation( {
		mutationFn: ( clientId: string ) => assignSalesClient( clientId ).then( ( data ) => data.data ),
		mutationKey: [ "salesRep" ],
	} )

export const useDeleteSalesClient = () =>
	useMutation( {
		mutationFn: ( clientId: string ) => deleteSalesClient( clientId ).then( ( data ) => data.data ),
		mutationKey: [ "salesRep" ],
	} )

export interface ResponseTypeSalesRepresentative {
	id: string
	entityId: number
	firstName: string
	lastName: string
	email: string
	contactNumber: any
	password: string
	description: any
	profilePictureUri: any
	status: boolean
	clinicalPreference: any
	emailNotificationsSubscribed: boolean
	resetPasswordCode: any
	lastPermissionUpdatedOn: any
	isSuperAdmin: any
	specialRole: string
	deleted: boolean
	deletedAt: any
	deletedBy: any
	parentAccountId: any
	roleId: any
	createdAt: string
	updatedAt: string
	parentAccount: ParentAccount
	role: Role
}

export interface ParentAccount {
	firstName: any
	lastName: any
	id: any
	email: any
}

export interface Role {
	name: any
	id: any
}

export type SalesRepresentative = {
	salesRepresentative: {}[]
}

export interface AssignedDoctorResponse {
	id: string
	deleted: boolean
	deletedAt: any
	deletedBy: any
	doctorId: string
	salesId: string
	createdAt: string
	updatedAt: string
	saleClientDoctor: {
		id: string
		firstName: string
		lastName: string
		email: string
		entityId?: string
		status?: string
	}
}

export interface SalesClientsAssociactedToDoctor {
	id: string
	deleted: boolean
	deletedAt: any
	deletedBy: any
	doctorId: string
	salesId: string
	createdAt: string
	updatedAt: string
	salesClientSalesUser: SalesClientSalesUser
}

export interface SalesClientSalesUser {
	id: string
	firstName: string
	lastName: string
	email: string
}
