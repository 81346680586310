import { Button, ButtonGroup, Modal } from "react-bootstrap"
import { useGetPaymentPriceQuery } from "../../../services/CelebPayments.Service"
import { useTaskCompletionDialogStore } from "../../../store/taskCompletionDialogStore"

const PaymentTaskDailog = () => {
  const { PaymentTaskModalOpen, setPaymentTaskModalOpen ,setPaymentApproval} = useTaskCompletionDialogStore()
  const { data: price } = useGetPaymentPriceQuery(PaymentTaskModalOpen!)
  console.log(price)
  return (
    <Modal show={PaymentTaskModalOpen ? true : false}>
      <Modal.Header>
        ApprovalTaskDailog
      </Modal.Header>
      <Modal.Body>
        Do You want to complete the payment
        <ButtonGroup aria-label="Basic example">
          <Button variant="success" onClick={()=>{setPaymentApproval(true);setPaymentTaskModalOpen(null)}}>Yes</Button>
          <Button variant="secondary" onClick={()=>{setPaymentApproval(false);setPaymentTaskModalOpen(null)}}>no</Button>
        </ButtonGroup>
      </Modal.Body>
    </Modal>
  )
}

export default PaymentTaskDailog