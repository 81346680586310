import { create } from "zustand"
// Define the state type
export type TeethState = {
	[key: string]: "available" | "selected" | "disabled"
}
const resetTooth: TeethState = {
	UR1: "available",
	UR2: "available",
	UR3: "available",
	UR4: "available",
	UR5: "available",
	UR6: "available",
	UR7: "available",
	UR8: "available",
	UL1: "available",
	UL2: "available",
	UL3: "available",
	UL4: "available",
	UL5: "available",
	UL6: "available",
	UL7: "available",
	UL8: "available",
	LR1: "available",
	LR2: "available",
	LR3: "available",
	LR4: "available",
	LR5: "available",
	LR6: "available",
	LR7: "available",
	LR8: "available",
	LL1: "available",
	LL2: "available",
	LL3: "available",
	LL4: "available",
	LL5: "available",
	LL6: "available",
	LL7: "available",
	LL8: "available",
	disabledUpper: "available",
	disabledLower: "available",
}
export type toothCondition = "available" | "selected" | "disabled"
// Create the Zustand store
export const useTeethStore = create<ToothStore>( ( set ) => ( {
	teethExtraction: {
		UR1: "available",
		UR2: "available",
		UR3: "available",
		UR4: "available",
		UR5: "available",
		UR6: "available",
		UR7: "available",
		UR8: "available",
		UL1: "available",
		UL2: "available",
		UL3: "available",
		UL4: "available",
		UL5: "available",
		UL6: "available",
		UL7: "available",
		UL8: "available",
		LR1: "available",
		LR2: "available",
		LR3: "available",
		LR4: "available",
		LR5: "available",
		LR6: "available",
		LR7: "available",
		LR8: "available",
		LL1: "available",
		LL2: "available",
		LL3: "available",
		LL4: "available",
		LL5: "available",
		LL6: "available",
		LL7: "available",
		LL8: "available",
		disabledUpper: "available",
		disabledLower: "available",
	},
	teethBonding: {
		UR1: "available",
		UR2: "available",
		UR3: "available",
		UR4: "available",
		UR5: "available",
		UR6: "available",
		UR7: "available",
		UR8: "available",
		UL1: "available",
		UL2: "available",
		UL3: "available",
		UL4: "available",
		UL5: "available",
		UL6: "available",
		UL7: "available",
		UL8: "available",
		LR1: "available",
		LR2: "available",
		LR3: "available",
		LR4: "available",
		LR5: "available",
		LR6: "available",
		LR7: "available",
		LR8: "available",
		LL1: "available",
		LL2: "available",
		LL3: "available",
		LL4: "available",
		LL5: "available",
		LL6: "available",
		LL7: "available",
		LL8: "available",
		disabledUpper: "available",
		disabledLower: "available",
	},
	teethReplacement: {
		UR1: "available",
		UR2: "available",
		UR3: "available",
		UR4: "available",
		UR5: "available",
		UR6: "available",
		UR7: "available",
		UR8: "available",
		UL1: "available",
		UL2: "available",
		UL3: "available",
		UL4: "available",
		UL5: "available",
		UL6: "available",
		UL7: "available",
		UL8: "available",
		LR1: "available",
		LR2: "available",
		LR3: "available",
		LR4: "available",
		LR5: "available",
		LR6: "available",
		LR7: "available",
		LR8: "available",
		LL1: "available",
		LL2: "available",
		LL3: "available",
		LL4: "available",
		LL5: "available",
		LL6: "available",
		LL7: "available",
		LL8: "available",
		disabledUpper: "available",
		disabledLower: "available",
	},
	toggleToothExtraction: ( toothId: string ) =>
		set( ( state ) => ( {
			teethExtraction: {
				...state.teethExtraction,
				[ toothId ]: toogleCondition( state.teethBonding[ toothId ], "teethExtraction" ),
			},
		} ) ),
	toggleToothBonding: ( toothId: string, condtion?: toothCondition | "disabled" ) =>
		set( ( state ) => ( {
			teethBonding: {
				...state.teethBonding,
				[ toothId ]: condtion ? condtion : toogleCondition( state.teethBonding[ toothId ] ),
			},
		} ) ),
	toggleToothReplacment: ( toothId: string, condtion?: toothCondition | "disabled" ) =>
		set( ( state ) => ( {
			teethReplacement: {
				...state.teethReplacement,
				[ toothId ]: condtion ? condtion : toogleCondition( state.teethReplacement[ toothId ] ),
			},
		} ) ),
	setDisabledUpper: ( disabled: toothCondition ) =>
		set( ( state ) => ( {
			teethExtraction: {
				...state.teethExtraction,
				disabledUpper: disabled,
			},
		} ) ),
	setDisabledLower: ( disabled: toothCondition ) =>
		set( ( state ) => ( {
			teethExtraction: {
				...state.teethExtraction,
				disabledLower: disabled,
			},
		} ) ),
	resetTeethSelection: () =>
		set( () => ( {
			teethExtraction: resetTooth,
			teethBonding: resetTooth,
			teethReplacement: resetTooth,
		} ) ),

	resetUpperTeethSelection: ( jaw?: string ) =>
		set( ( state ) => ( {
			teethBonding: resetSelectedTeeth( state.teethBonding, jaw ),
			teethExtraction: resetSelectedTeeth( state.teethExtraction, jaw ),
			teethReplacement: resetSelectedTeeth( state.teethExtraction, jaw ),
		} ) ),
	toggleToothBondingJsonFile: ( jsonFile: TeethState ) =>
		set( ( state ) => ( {
			teethBonding: {
				...state.teethBonding,
				...jsonFile,
			},
		} ) ),
	toggleToothExtractionJsonFile: ( jsonFile: TeethState ) =>
		set( ( state ) => ( {
			teethExtraction: {
				...state.teethExtraction,
				...jsonFile,
			},
		} ) ),
} ) )

interface ToothStore {
	teethExtraction: TeethState
	teethBonding: TeethState
	teethReplacement: TeethState
	toggleToothExtraction: ( toothId: string, condtion?: toothCondition ) => void
	toggleToothReplacment: ( toothId: string, condtion?: toothCondition ) => void
	setDisabledUpper: ( disabled: toothCondition ) => void
	setDisabledLower: ( disabled: toothCondition ) => void
	resetTeethSelection: () => void
	resetUpperTeethSelection: ( jaw: string ) => void
	toggleToothBonding: ( toothId: string, condtion?: toothCondition ) => void
	toggleToothBondingJsonFile: ( jsonFile: TeethState ) => void
	toggleToothExtractionJsonFile: ( jsonFile: TeethState ) => void
}

const resetSelectedTeeth = ( teethState: TeethState, jaw: string | undefined ): TeethState => {
	const resetState = { ...teethState }
	if ( jaw ) {
		Object.keys( resetState ).forEach( ( key ) => {
			if ( key.includes( jaw ) ) {
				resetState[ key ] = "available"
			}
		} )
	}
	return resetState
}

const toogleCondition = ( data: toothCondition, teethExtraction?: string ): toothCondition => {
	if ( data == "available" ) {
		return "selected"
	} else if ( data == "selected" && teethExtraction ) {
		return "selected"
	} else {
		return "available"
	}
}
