import { z } from "zod"
import { create } from "zustand"
type EditId = string | null

interface AccountDialogState {
	listOpen: boolean
	setListOpen: ( next: boolean ) => void

	editOpen: EditId
	setEditOpen: ( next: EditId ) => void

	createOpen: boolean
	setCreateOpen: ( next: boolean ) => void

	resetPasswordModal: resetPassword
	setResetPasswordModal: ( next: resetPassword ) => void

	typesTypesList: boolean
	setTypesTypesList: ( next: boolean ) => void

	typesEditOpen: EditId
	setTypesEditOpen: ( next: EditId ) => void

	typesCreateOpen: boolean
	setTypesCreateOpen: ( next: boolean ) => void

	permissions: PermissionType[]
	setPermissions: ( newPermissions: PermissionType[] ) => void
	addPermission: ( permission: PermissionType ) => void
	removePermission: ( taskId: string ) => void

	genericPermissions: GenericPermissionType[]
	setGenericPermissions: ( newPermissions: GenericPermissionType[] ) => void
	addGenericPermissions: ( permission: GenericPermissionType ) => void
	removeGenericPermissions: ( taskId: string ) => void

	serviceOpted: string[]
	setServiceOpted: ( next: string[] ) => void

	resetState: () => void
}

export const useAccountsDialogStore = create<AccountDialogState>()( ( set ) => ( {
	listOpen: false,
	setListOpen: ( next: boolean ) => set( { listOpen: next } ),
	editOpen: null,
	setEditOpen: ( next: EditId ) => set( { editOpen: next } ),
	createOpen: false,
	setCreateOpen: ( next: boolean ) => set( { createOpen: next } ),
	resetPasswordModal: { show: false, name: "", userId: "" },
	setResetPasswordModal: ( next: resetPassword ) => set( { resetPasswordModal: next } ),
	typesTypesList: false,
	setTypesTypesList: ( next: boolean ) => set( { typesTypesList: next } ),
	typesEditOpen: null,
	setTypesEditOpen: ( next: EditId ) => set( { typesEditOpen: next } ),
	typesCreateOpen: false,
	setTypesCreateOpen: ( next: boolean ) => set( { typesCreateOpen: next } ),

	permissions: [],
	setPermissions: ( newPermissions ) => set( { permissions: newPermissions } ),
	addPermission: ( permission ) =>
		set( ( state ) => ( {
			permissions: [ ...state.permissions, permission ],
		} ) ),
	removePermission: ( taskId ) =>
		set( ( state ) => ( {
			permissions: state.permissions.filter( ( p ) => p.taskId !== taskId ),
		} ) ),

	genericPermissions: [],
	setGenericPermissions: ( newPermissions ) => set( { genericPermissions: newPermissions } ),
	addGenericPermissions: ( permission ) =>
		set( ( state ) => ( {
			genericPermissions: [ ...state.genericPermissions, permission ],
		} ) ),
	removeGenericPermissions: ( taskId ) =>
		set( ( state ) => ( {
			genericPermissions: state.genericPermissions.filter( ( p ) => p.permissionId !== taskId ),
		} ) ),

	serviceOpted: [],
	setServiceOpted: ( next: string[] ) =>
		set( ( state ) => ( {
			serviceOpted: next,
		} ) ),

	resetState: () =>
		set( {
			editOpen: null,
			createOpen: false,
			typesTypesList: false,
			typesEditOpen: null,
			typesCreateOpen: false,
			permissions: [],
			genericPermissions: [],
			serviceOpted: [],
		} ),
} ) )

const permissionSchema = z.object( { taskId: z.string(), permission: z.number() } )
const genericPermissionSchema = z.object( { permissionId: z.string(), permission: z.number() } )
const resetPasswordSchema = z.object( { show: z.boolean(), userId: z.string(), name: z.string() } )

export type PermissionType = z.infer<typeof permissionSchema>
export type GenericPermissionType = z.infer<typeof genericPermissionSchema>
export type resetPassword = z.infer<typeof resetPasswordSchema>
export interface TaskPermissionType {
	id: string
	permission: number
	roleId: string
	taskId: string
	createdAt: string
	updatedAt: string
	roleTaskPermissionTask: RoleTaskPermissionTaskType
}

export interface RoleTaskPermissionTaskType {
	id: string
	name: string
	description: any
	writeNote: boolean
	uploadFile: boolean
	checkmark: boolean
	category: string
	paymentStage: boolean
	replaceOrderTask: boolean
	daysToComplete: any
	previousTasks: string[]
	rollBackToTask: string[]
	isActive: any
	taskAssignedRole: string
	serviceId: string
	createdAt: string
	updatedAt: string
}
