import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { zodResolver } from "@hookform/resolvers/zod"
import { useState } from "react"
import { Form, InputGroup, Modal, Nav, Navbar } from "react-bootstrap"
import { useForm } from "react-hook-form"
import { ResetPasswordRequest, ResetPasswordSchema } from "../../services/CelebAuth.Service"
import { useResetPasswordAdminMutation } from "../../services/CelebUser.Service"
import { useAccountsDialogStore } from "../../store/accountsDialogStore"
import { ToastPopup } from "../toast/Toast"

export const ResetPasswordDialog = () => {
	const resetPassword = useAccountsDialogStore( ( state ) => state.resetPasswordModal )
	const setResetModal = useAccountsDialogStore( ( state ) => state.setResetPasswordModal )

	const closeModal = () => {
		setResetModal( { show: false, name: "", userId: "" } )
	}

	return (
		<Modal show={resetPassword.show} onHide={closeModal} size="lg" backdrop="static" centered>
			<Navbar style={{ backgroundColor: "#d9d9d9" }} className="modal-header">
				<Nav className="w-100">
					<Nav.Link className="nav-link-opposite active mt-1">Reset Password</Nav.Link>
				</Nav>
			</Navbar>
			<Modal.Body className="p-4">
				<h6 className="text-center">Reset Password for {resetPassword.name}</h6>
				<PasswordResetDialogImpl
					closeDialog={closeModal}
					mainData={{ id: resetPassword.userId, name: resetPassword.name }}
				/>
			</Modal.Body>
		</Modal>
	)
}

const PasswordResetDialogImpl = ( {
	closeDialog,
	mainData,
}: {
	closeDialog: () => void
	mainData: { id: string; name: string }
} ) => {
	const methods = useForm<ResetPasswordRequest>( {
		resolver: zodResolver( ResetPasswordSchema ),
		mode: "onSubmit",
	} )
	const {
		register,
		handleSubmit,
		getValues,
		reset,
		formState: { errors },
	} = methods
	const [ showPassword, setShowPassword ] = useState( false )
	const [ showConfirmPassword, setShowConfirmPassword ] = useState( false )
	const { mutateAsync: resetPasswordAdmin } = useResetPasswordAdminMutation()

	const onSubmit = async ( data: ResetPasswordRequest ) => {
		try {
			await resetPasswordAdmin( { id: mainData.id, password: data.password } )
			closeDialog()
		} catch ( error ) {
			console.error( error )
		}
	}

	const onCancel = () => {
		reset()
		closeDialog()
	}

	return (
		<>
			<Form
				onSubmit={handleSubmit( onSubmit )}
				style={{ width: "100%", maxWidth: "400px", margin: "0 auto" }}
			>
				{errors.root?.message && (
					<div className="text-danger text-center mb-3">{errors.root.message}</div>
				)}
				<InputGroup className="mb-3">
					<Form.Control
						{...register( "password", { required: "Password is required" } )}
						type={showPassword ? "text" : "password"}
						isInvalid={!!errors.password}
						placeholder="New Password"
						autoComplete="current-password"
						style={{ borderRadius: "4px" }}
					/>
					<InputGroup.Text
						onClick={() => setShowPassword( !showPassword )}
						style={{ cursor: "pointer" }}
					>
						<FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
					</InputGroup.Text>
					{errors.password && (
						<div className="invalid-feedback" style={{ display: "block" }}>
							{errors.password.message}
						</div>
					)}
				</InputGroup>
				<InputGroup className="mb-3">
					<Form.Control
						{...register( "confirmPassword", {
							required: "Confirm password is required",
							validate: ( value ) => {
								const password = getValues( "password" )
								return value === password || "Passwords do not match"
							},
						} )}
						type={showConfirmPassword ? "text" : "password"}
						isInvalid={!!errors.confirmPassword}
						placeholder="Confirm Password"
						autoComplete="current-password"
						style={{ borderRadius: "4px" }}
					/>
					<InputGroup.Text
						onClick={() => setShowConfirmPassword( !showConfirmPassword )}
						style={{ cursor: "pointer" }}
					>
						<FontAwesomeIcon icon={showConfirmPassword ? faEyeSlash : faEye} />
					</InputGroup.Text>
					{errors.confirmPassword && (
						<div className="invalid-feedback" style={{ display: "block" }}>
							{errors.confirmPassword.message}
						</div>
					)}
				</InputGroup>
				<div className="text-center">
					<button className="btn btn-secondary me-2" type="button" onClick={onCancel}>
						Cancel
					</button>
					<button className="btn btn-primary" type="submit">
						Submit
					</button>
				</div>
			</Form>
		</>
	)
}

export default ResetPasswordDialog
