import Pagination from "rc-pagination/lib/Pagination"
import React from "react"
import { Modal, Nav, Table } from "react-bootstrap"
import Moment from "react-moment"
import LoadingOverlay from "../../../assets/styles/components/loader/loadingOverlay"
import { constants } from "../../../services/CelebApiConstants"
import {
	useDeleteServiceMutation,
	useListServicesQuery,
} from "../../../services/CelebService.Service"
import { useServiceDialogStore } from "../../../store/serviceDialogStore"
import { useLoadingStore, userSessionStore } from "../../../store/userSessionStore"
import SearchInput from "../../form/SearchInput"
import { AccountDeleteDialog, DeleteDialog } from "../DeleteDialog"

import { faPencil, faTrash } from "@fortawesome/free-solid-svg-icons"
import { CheckGenericPermission } from "../../permissions/CheckPermision"
import { EditTrash } from "../../utlity/EditTrash"
import ServicesAddDialog from "./ServicesAdd.dialog"
import ServicesEditDialog from "./ServicesEdit.dialog"

const ServiceListDialog = () => {
	const listOpen = useServiceDialogStore( ( state ) => state.listOpen )
	const setListOpen = useServiceDialogStore( ( state ) => state.setListOpen )
	return (
		<React.Suspense>
			<Modal show={listOpen} onHide={() => setListOpen( false )} size="xl" backdrop="static">
				<ServicesListDialogImpl />
			</Modal>
		</React.Suspense>
	)
}

const ServicesListDialogImpl = () => {
	const sessionUser = userSessionStore( ( state: any ) => state.user )
	const { isLoading } = useLoadingStore()
	const deleteDialogref = React.useRef<AccountDeleteDialog>( null )
	const [ currentPage, setCurrentPage ] = React.useState( 1 )
	const [ query, setQuery ] = React.useState( "" )
	const pageOffset = ( currentPage - 1 ) * 10
	const serviceStore = useServiceDialogStore()
	const showAddDialog = () => {
		serviceStore.setCreateOpen( true )
	}

	const {
		data: { services, total },
	} = useListServicesQuery( {
		pageOffset,
		query: query,
		limit: constants.PAGE_COUNT,
	} )

	const { mutateAsync: deleteUserAsync } = useDeleteServiceMutation()

	const deleteService = async ( id: string ) => {
		await deleteUserAsync( id )
	}

	const handlePagination = ( pageNumber: number ) => {
		setCurrentPage( pageNumber )
	}

	const onQueryChange = ( q: string ) => {
		setQuery( q )
		setCurrentPage( 1 )
	}

	return (
		<React.Fragment>
			<NavbarService />
			<Modal.Body>
				<LoadingOverlay isLoading={isLoading} />
				<div className="d-flex justify-content-between my-2">
					<SearchInput callback={onQueryChange} style={{ width: "25%" }} />
					<CheckGenericPermission genericLabel={"Service Create"}>
						<button className="float-end btn btn-primary" onClick={showAddDialog}>
							Create Service
						</button>
					</CheckGenericPermission>
				</div>

				<Table striped bordered hover>
					<thead>
						<tr>
							<th>Service ID</th>
							<th>Name</th>
							<th>Status</th>
							<th>
								Creating <br />
								Account ID
							</th>
							<th>
								Creating <br />
								Account Name
							</th>
							<th>Created On</th>
							<th>Creating Email</th>
							<th>Action</th>
						</tr>
					</thead>
					<tbody>
						<CheckGenericPermission genericLabel={"Service List"}>
							{services.map( ( service ) => (
								<tr key={service.id}>
									<td>{service.entityId}</td>
									<td>{service.name}</td>
									<td>{service.isActive ? "Active" : "Inactive"}</td>
									<td>48573</td>
									<td>Super Admin</td>
									<td>
										<Moment format="MM/DD/YYYY hh:mma">{service.createdAt}</Moment>
									</td>
									<td>admin@gmail.com</td>
									<td className="text-end">
										<CheckGenericPermission genericLabel={"Service Edit"}>
											<EditTrash
												editIcon={{ icon: faPencil, color: "#8faadc" }}
												trashIcon={{ icon: faTrash, color: "#717273" }}
												editCallback={() => {
													serviceStore.setEditOpen( service.id )
												}}
												deleteCallBack={() => deleteDialogref.current?.showDialog( service )}
											/>
										</CheckGenericPermission>
									</td>
								</tr>
							) )}
						</CheckGenericPermission>
					</tbody>
				</Table>
				<Pagination
					total={total}
					pageSize={constants.PAGE_COUNT}
					onChange={handlePagination}
					align={"center"}
				/>
			</Modal.Body>
			<DeleteDialog
				ref={deleteDialogref}
				onDeleteOkCallback={deleteService}
				deletingEntityName="name"
			/>
			{serviceStore.createOpen && <ServicesAddDialog />}
			{serviceStore.editOpen && <ServicesEditDialog />}
		</React.Fragment>
	)
}

const NavbarService = () => {
	return (
		<Modal.Header closeButton>
			<Nav variant="tabs" className="mt-2">
				<Nav.Item>
					<span className="nav-link active">Services</span>
				</Nav.Item>
				<Nav.Item>
					<span className="nav-link">Pricing</span>
				</Nav.Item>
			</Nav>
		</Modal.Header>
	)
}

export default ServiceListDialog
