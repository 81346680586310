import { useSuspenseQuery } from "@tanstack/react-query"
import axios from "axios"
import { z } from "zod"
import { constants } from "./CelebApiConstants"

export class CelebGenericPermissionService {
	static _instance: CelebGenericPermissionService = new CelebGenericPermissionService()

	private constructor() {
		CelebGenericPermissionService._instance = this
	}

	static getInstance(): CelebGenericPermissionService {
		return CelebGenericPermissionService._instance
	}

	async listPermissions() {
		const requestUrl = `${constants.BASE_URL}${constants.API_V1}${constants.GENERIC_PERMISSIONS}`
		return await axios.get( requestUrl )
	}
}

export const useListPermissionsQuery = () =>
	useSuspenseQuery( {
		queryKey: [ "list", constants.GENERIC_PERMISSIONS ],
		queryFn: () =>
			CelebGenericPermissionService.getInstance()
				.listPermissions()
				.then( ( res ) => res.data.data ),
	} )

const GenericPermissionSchema = z.object( {
	id: z.string(),
	name: z.string(),
	permissionCode: z.string(),
	description: z.string(),
	createdAt: z.string().datetime(),
	updatedAt: z.string().datetime(),
} )
