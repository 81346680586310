import { useMutation } from "@tanstack/react-query"
import { z } from "zod"
import { queryClient } from "../providers/QueryProvider"
import { usePatientDialogStore } from "../store/patientDailogStore"
import { userSessionStore } from "../store/userSessionStore"
import { constants } from "./CelebApiConstants"
import { CelebRequestGeneratorService } from "./CelebRequestGenerator.Service"
export class CelebPatientService {
	static _instance: CelebPatientService = new CelebPatientService()

	private constructor() {
		CelebPatientService._instance = this
	}

	static getInstance(): CelebPatientService {
		return CelebPatientService._instance
	}

	async create( data: PatientSchemaType ): Promise<any> {
		const userDataStr = localStorage.getItem( "user" )
		if ( !userDataStr ) {
			throw new Error( "User data not found in localStorage" )
		}

		const user = JSON.parse( userDataStr )
		const adminApi = user.isAdmin ? `${constants.DOCTOR}/${data.assignedDoctor}` : ""
		const requestUrl = constants.PATIENTS + adminApi
		const response = await CelebRequestGeneratorService.processPostRequest( requestUrl, data )

		try {
			if ( response.data.status === 400 ) {
				throw new Error( "Patient already exists" )
			}
			return response.data
		} catch ( error ) {
			if ( error instanceof Error ) {
				throw error
				const { setErrorToShow } = usePatientDialogStore()
				setErrorToShow( response.data.message )
			}
		}
	}

	async createByAdmin( data: PatientSchemaType, doctorId: string ): Promise<any> {
		const requestUrl = `${constants.PATIENTS}${constants.DOCTOR}/${doctorId}`
		return await CelebRequestGeneratorService.processPostRequest( requestUrl, data )
	}

	async placeReplaceOrder( treatmentId: string | null, data: ReplaceOrderSchemaType ): Promise<any> {
		const requestUrl = `${constants.PATIENTS}/replaceorder/${treatmentId}`
		return await CelebRequestGeneratorService.processPostRequest( requestUrl, data )
	}

	async editOrder( id: string | null, data: PatientSchemaType ): Promise<any> {
		const requestUrl = `${constants.TREATMENT_PLAN}/${id}`
		return await CelebRequestGeneratorService.processPutRequest( requestUrl, data )
	}
}

export const usePatientCreateMutation = () => {
	const { setErrorToShow } = usePatientDialogStore()
	const { setProgressLoaderJson } = userSessionStore()
	return useMutation( {
		mutationFn: ( payload: PatientSchemaType ) =>
			CelebPatientService.getInstance()
				.create( payload )
				.then( ( data ) => data.data )
				.then( ( data ) => data )
				.catch( ( error ) => {
					throw error
				} ),

		onSuccess: () => queryClient.invalidateQueries( { queryKey: [ "treatment" ], exact: false } ),
		onError: ( error ) => {
			console.error( "Mutation error:", error )
			setProgressLoaderJson( {} )
			setErrorToShow( "Something went wrong. Please retry" )
		},
	} )
}

export const usePatientEditMutation = () => {
	const { setErrorToShow } = usePatientDialogStore()
	const { setProgressLoaderJson } = userSessionStore()
	return useMutation( {
		mutationFn: ( { id, payload }: { id: string | null; payload: PatientSchemaType } ) =>
			CelebPatientService.getInstance()
				.editOrder( id, payload )
				.then( ( data ) => data.data )
				.then( ( data ) => data )
				.catch( ( error ) => {
					throw error
				} ),

		onSuccess: () => queryClient.invalidateQueries( { queryKey: [ "treatment" ], exact: false } ),
		onError: ( error ) => {
			console.error( "Mutation error:", error )
			setProgressLoaderJson( {} )
			setErrorToShow( "Something went wrong. Please retry" )
		},
	} )
}

export const usePatientReplaceMutation = () =>
	useMutation( {
		mutationFn: ( { id, payload }: { id: string | null; payload: ReplaceOrderSchemaType } ) =>
			CelebPatientService.getInstance()
				.placeReplaceOrder( id, payload )
				.then( ( data ) => data.data )
				.then( ( data ) => data ),

		onSuccess: () => queryClient.invalidateQueries( { queryKey: [ "treatment" ], exact: false } ),
	} )

export const PatientSchema = z
	.object( {
		id: z.string().optional(),
		firstName: z.string().min( 1, { message: "Please enter correct name" } ),
		lastName: z.string().min( 1, { message: "Please enter correct last name" } ),
		office: z.string().optional(),
		gender: z.string().min( 1, { message: "Please select gender" } ),
		dob: z.string(),
		selectedOffice: z.string().min( 1, { message: "Please Select alteast one office" } ),
		assignedDoctor: z.string(),
		doctorsWithAccess: z.array( z.string() ).optional(),
		ethenicity: z.string().min( 1, { message: "please select Ethnicity" } ),
		phone: z.string().optional(),
		email: z.string().optional(),
		treatmentPlan: z.object( {
			treatmentPlanDoctorAddressId: z.string(),
			serviceId: z.string().min( 1, { message: "minimum 1" } ),
			doctorsWithAccess: z.array( z.string() ),
			instruction: z.string().min( 1, { message: "Please add instructions" } ),
			teethExtraction: z.object( {
				UR1: z.any(),
				UR2: z.any(),
				UR3: z.any(),
				UR4: z.any(),
				UR5: z.any(),
				UR6: z.any(),
				UR7: z.any(),
				UR8: z.any(),
				UL1: z.any(),
				UL2: z.any(),
				UL3: z.any(),
				UL4: z.any(),
				UL5: z.any(),
				UL6: z.any(),
				UL7: z.any(),
				UL8: z.any(),
				LR1: z.any(),
				LR2: z.any(),
				LR3: z.any(),
				LR4: z.any(),
				LR5: z.any(),
				LR6: z.any(),
				LR7: z.any(),
				LR8: z.any(),
				LL1: z.any(),
				LL2: z.any(),
				LL3: z.any(),
				LL4: z.any(),
				LL5: z.any(),
				LL6: z.any(),
				LL7: z.any(),
				LL8: z.any(),
			} ),
			teethBonding: z.object( {
				UR1: z.any(),
				UR2: z.any(),
				UR3: z.any(),
				UR4: z.any(),
				UR5: z.any(),
				UR6: z.any(),
				UR7: z.any(),
				UR8: z.any(),
				UL1: z.any(),
				UL2: z.any(),
				UL3: z.any(),
				UL4: z.any(),
				UL5: z.any(),
				UL6: z.any(),
				UL7: z.any(),
				UL8: z.any(),
				LR1: z.any(),
				LR2: z.any(),
				LR3: z.any(),
				LR4: z.any(),
				LR5: z.any(),
				LR6: z.any(),
				LR7: z.any(),
				LR8: z.any(),
				LL1: z.any(),
				LL2: z.any(),
				LL3: z.any(),
				LL4: z.any(),
				LL5: z.any(),
				LL6: z.any(),
				LL7: z.any(),
				LL8: z.any(),
			} ),
			upperBracketSlotSize: z.string(),
			lowerBracketSlotSize: z.string(),
			treatmentStart: z.number(),
			treatmentFiles: z
				.array(
					z.object( {
						category: z.string(),
						subCategory: z.string(),
						originalFileName: z.string(),
						uri: z.string(),
					} ),
				)
				.optional(),
		} ),
	} )
	.refine(
		( data ) => {
			const teethExtractionValues = Object.values( data.treatmentPlan.teethExtraction )
			const teethBondingValues = Object.values( data.treatmentPlan.teethBonding )
			const extractionSelected = teethExtractionValues.some(
				( value ) => value === "selected" || value === "disabled",
			)
			const bondingSelected = teethBondingValues.some( ( value ) => value === "selected" )
			console.log( "extractionSelected", extractionSelected, "bondingSelected", bondingSelected )
			return extractionSelected || bondingSelected
		},
		{
			message:
				"At least one tooth from either tooth bonding or tooth extraction must be selected or disabled",
			path: [ "treatmentPlan" ],
		},
	)

export type PatientSchemaType = z.infer<typeof PatientSchema>
export const teethIdJSON: TeethExtractionType = {
	upperSection: {
		right: {
			UR1: false,
			UR2: false,
			UR3: false,
			UR4: false,
			UR5: false,
			UR6: false,
			UR7: false,
			UR8: false,
		},
		left: {
			UL1: false,
			UL2: false,
			UL3: false,
			UL4: false,
			UL5: false,
			UL6: false,
			UL7: false,
			UL8: false,
		},
	},
	lowerSection: {
		right: {
			LR1: false,
			LR2: false,
			LR3: false,
			LR4: false,
			LR5: false,
			LR6: false,
			LR7: false,
			LR8: false,
		},
		left: {
			LL1: false,
			LL2: false,
			LL3: false,
			LL4: false,
			LL5: false,
			LL6: false,
			LL7: false,
			LL8: false,
		},
	},
}
export type TeethExtractionType = {
	upperSection: {
		right: {
			UR1: false
			UR2: false
			UR3: false
			UR4: false
			UR5: false
			UR6: false
			UR7: false
			UR8: false
			[key: string]: boolean
		}
		left: {
			UL1: false
			UL2: false
			UL3: false
			UL4: false
			UL5: false
			UL6: false
			UL7: false
			UL8: false
			[key: string]: boolean
		}
		[key: string]: {}
	}
	lowerSection: {
		right: {
			LR1: false
			LR2: false
			LR3: false
			LR4: false
			LR5: false
			LR6: false
			LR7: false
			LR8: false
			[key: string]: boolean
		}
		left: {
			LL1: false
			LL2: false
			LL3: false
			LL4: false
			LL5: false
			LL6: false
			LL7: false
			LL8: false
			[key: string]: boolean
		}
		[key: string]: {}
	}
	[key: string]: {}
}

export const teethExtractionSavingObj: { [key: string]: boolean } = {
	UR1: false,
	UR2: false,
	UR3: false,
	UR4: false,
	UR5: false,
	UR6: false,
	UR7: false,
	UR8: false,
	UL1: false,
	UL2: false,
	UL3: false,
	UL4: false,
	UL5: false,
	UL6: false,
	UL7: false,
	UL8: false,
	LR1: false,
	LR2: false,
	LR3: false,
	LR4: false,
	LR5: false,
	LR6: false,
	LR7: false,
	LR8: false,
	LL1: false,
	LL2: false,
	LL3: false,
	LL4: false,
	LL5: false,
	LL6: false,
	LL7: false,
	LL8: false,
	disabledUpper: false,
	disabledLower: false,
}

export const ReplaceOrderSchema = z.object( {
	firstName: z.string().min( 1, { message: "Please enter correct name" } ),
	lastName: z.string(),
	office: z.string().optional(),
	gender: z.string().min( 1, { message: "Please select gender" } ),
	dob: z.string(),
	selectedOffice: z.string().min( 1, { message: "Please Select alteast one office" } ),
	assignedDoctor: z.string(),
	doctorsWithAccess: z.array( z.string() ).optional(),
	ethenicity: z.string().min( 1, { message: "Please select Ethnicity" } ),
	phone: z.string().optional(),
	email: z.string().optional(),
	serviceId: z.string(),
	replaceOrder: z
		.object( {
			braces: z.object( {
				UR1: z.any(),
				UR2: z.any(),
				UR3: z.any(),
				UR4: z.any(),
				UR5: z.any(),
				UR6: z.any(),
				UR7: z.any(),
				UR8: z.any(),
				UL1: z.any(),
				UL2: z.any(),
				UL3: z.any(),
				UL4: z.any(),
				UL5: z.any(),
				UL6: z.any(),
				UL7: z.any(),
				UL8: z.any(),
				LR1: z.any(),
				LR2: z.any(),
				LR3: z.any(),
				LR4: z.any(),
				LR5: z.any(),
				LR6: z.any(),
				LR7: z.any(),
				LR8: z.any(),
				LL1: z.any(),
				LL2: z.any(),
				LL3: z.any(),
				LL4: z.any(),
				LL5: z.any(),
				LL6: z.any(),
				LL7: z.any(),
				LL8: z.any(),
			} ),
			note: z.string(),
			upperWires: z.preprocess(
				( val ) => ( Array.isArray( val ) ? val : [] ),
				z.array( z.string() ).optional(),
			),
			lowerWires: z.preprocess(
				( val ) => ( Array.isArray( val ) ? val : [] ),
				z.array( z.string() ).optional(),
			),
		} )
		.refine(
			( data ) => {
				const bracesSelected = Object.values( data.braces ).find( ( d ) => d.includes( "selected" ) )
				const upperWiresSelected = data.upperWires && data.upperWires.length > 0
				const lowerWiresSelected = data.lowerWires && data.lowerWires.length > 0

				return bracesSelected || upperWiresSelected || lowerWiresSelected
			},
			{
				message: "At least one item must be selected in braces, upperWires, or lowerWires",
			},
		),
	treatmentPlan: z.object( { treatmentFiles: z.array( z.unknown() ).optional() } ).nullable(),
} )

export type ReplaceOrderSchemaType = z.infer<typeof ReplaceOrderSchema>
