import React from "react"
import { Badge, Dropdown, DropdownButton, Table } from "react-bootstrap"
import Moment from "react-moment"
import { Params, useParams } from "react-router"
import { useSearchParams } from "react-router-dom"
import {
	PatientEditSchema,
	useTaskCompleteServicesQuery,
	useTreatmentStatusMutation,
} from "../../services/CelebTreatment.Service"
import { usePatientDialogStore } from "../../store/patientDailogStore"
import { TreatmentStates } from "../../utils/appConstants"
import { CheckGenericPermission } from "../permissions/CheckPermision"
import { infoMapperNames } from "../utlity/GenricFunctions"
import SortButton from "../utlity/SortButton"
import CustomPagination from "../utlity/pagination-component"


const CompletedTable = () => {
	const paramsData: Readonly<Params<string>> = useParams()
	const [searchParams, setSearchParams] = useSearchParams()
	const patientStore = usePatientDialogStore()
	const [currentPage, setCurrentPage] = React.useState<number>(0)
	const [sortingData, setSortingData] = React.useState<string[] | null>()
	const status = searchParams.get("status")
	const { mutateAsync: updateTaskStatus } = useTreatmentStatusMutation()
	const searchPatient = searchParams.get("searchPatient")
	const doctorIdParam = searchParams.get("doctorId")

	const { data: tableData } = useTaskCompleteServicesQuery({
		search: searchPatient || "",
		limit: "10",
		offset: currentPage + "",
		serviceId: paramsData.id ? paramsData.id : "",
		status: status ? status : "",
		...(doctorIdParam && { ["doctorId"]: doctorIdParam }),
		...(sortingData && {
			["sortingParams"]: JSON.stringify(sortingData),
		}),
	})

	const onTreatmentStateChange = async (treatmentId: string, statusTask: string) => {
		updateTaskStatus({ treatmentId, status: statusTask })
	}

	const addNewQueryParam = (patientID: string) => {
		if (patientID) {
			const newParams = new URLSearchParams(searchParams)
			newParams.set("PatientId", patientID)
			setSearchParams(newParams)
		}
	}

	const handleSortChange = (apiParams: string[], sortOrder: "asc" | "desc" | undefined): void => {
		const updatedSortingData = sortOrder ? [...apiParams, sortOrder] : undefined
		setSortingData(updatedSortingData)
	}
	React.useEffect(() => {
		addNewQueryParam(tableData?.treatment[0]?.id)
	}, [status, paramsData.id, currentPage, tableData?.treatment[0]?.id])

	return (
		<React.Suspense>
			<Table striped hover size="sm">
				<thead>
					<tr className="table-header" >
						<th >
							Patient Name{" "}
							<SortButton sortKey="patient#firstName" onSortChange={handleSortChange} />
						</th>
						<th>
							Patient ID <SortButton sortKey="patient#entityId" onSortChange={handleSortChange} />
						</th>
						<th>
							Submit Date <SortButton sortKey="createdAt" onSortChange={handleSortChange} />
						</th>
						<th>
							Clinic{" "}
							<SortButton
								sortKey="treatmentPlanDoctorAddress#name"
								onSortChange={handleSortChange}
							/>
						</th>
						<th>
							Doctor <SortButton sortKey="doctor#firstName" onSortChange={handleSortChange} />
						</th>
						<th>Action Buttons</th>
					</tr>
				</thead>
				<tbody>
					{tableData &&
						tableData.treatment.map((x: PatientEditSchema) => {
							return (
								<tr
									className="table-data"
									data-Active={searchParams.get("PatientId") === x.id}
									onClick={() => addNewQueryParam(x.id)}
								>
									<td >
										<CheckGenericPermission genericLabel="Patient Name">
											{infoMapperNames(x.patient, [
												"entityId",
												"dob",
												"id",
												"gender",
												"ethenicity",
												"email",
												"phone",
											])}{" "}
										</CheckGenericPermission>
										<Badge bg="secondary" pill hidden={!x.isReplacementOrder}>
											Replaced order
										</Badge>
									</td>
									<td>
										{" "}
										<CheckGenericPermission genericLabel="Patient ID">
											{x.patient.entityId}
										</CheckGenericPermission>
									</td>
									<td>
										<CheckGenericPermission genericLabel="Patient Submit Date">
											{<Moment date={x.createdAt} format="MM/DD/YYYY" />}
										</CheckGenericPermission>
									</td>
									<td>
										{" "}
										<CheckGenericPermission genericLabel="Patient Clinic">
											{x.treatmentPlanDoctorAddress.name}
										</CheckGenericPermission>
									</td>
									<td>
										{" "}
										<CheckGenericPermission genericLabel="Patient Assigned Doctor">
											{x.doctor.firstName + " " + x.doctor.lastName}
										</CheckGenericPermission>
									</td>
									<td>
										<div className="d-flex gap-2 justify-content-center">
											<CheckGenericPermission genericLabel={"View TP"}>
												<a
													className="btn btn-primary"
													target="_blank"
													href={"/editor/noTreatmentAction?treatmentId=" + x.id}
												>
													View TP
												</a>
											</CheckGenericPermission>
											<DropdownButton id="dropdown-basic-button" title="">
												{status == "completed" && (
													<>
														<CheckGenericPermission genericLabel={"Patient Replace"}>
															<Dropdown.Item
																href="#PatientInfo"
																onClick={() => patientStore.setReplaceOpen(x.id)}
															>
																Replace
															</Dropdown.Item>
														</CheckGenericPermission>
														<CheckGenericPermission genericLabel={"Patient Archive"}>
															<Dropdown.Item
																onClick={() =>
																	onTreatmentStateChange(x.id, TreatmentStates.ARCHIVED)
																}
															>
																Archive
															</Dropdown.Item>
														</CheckGenericPermission>
													</>
												)}

												{status == TreatmentStates.PAUSED && (
													<>
														<Dropdown.Item
															onClick={() => onTreatmentStateChange(x.id, TreatmentStates.ACTIVE)}
														>
															Unpause
														</Dropdown.Item>
													</>
												)}

												{status == TreatmentStates.ARCHIVED && (
													<>
														<Dropdown.Item
															onClick={() =>
																onTreatmentStateChange(x.id, TreatmentStates.COMPLETED)
															}
														>
															Unarchive
														</Dropdown.Item>
													</>
												)}
												<CheckGenericPermission genericLabel={"Patient Transfer"}>
													<Dropdown.Item>Transfer</Dropdown.Item>
												</CheckGenericPermission>
											</DropdownButton>
										</div>
									</td>
								</tr>
							)
						})}
				</tbody>
			</Table>
			<CustomPagination 
			currentPage={currentPage/10} 
			total={tableData?.total} 
			onChange={( pagenumber ) => setCurrentPage( ( pagenumber ) * 10 )}/>
		</React.Suspense>
	)
}

export default CompletedTable
