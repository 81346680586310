export const GENERIC_PERMISSIONS = {
	"Info : First Name": "FN1",
	"Info : Last Name": "LN1",
	"Info : Birth Date": "BD1",
	"Info : Ethnicity": "ETH1",
	"Info : Office Address": "ODA1",
	"Menu Accounts": "MENU_ACC",
	"Menu Services": "MENU_SER",
	"Menu Financials": "MENU_FIN",
	"Menu Sales": "MENU_SAL",
	"Menu Data": "MENU_DAT",
	"Account Type List": "ACCTYP_LIST",
	"Account Type Create": "ACCTYP_CREATE",
	"Account Type Edit": "ACCTYP_EDIT",
	"Accounts List": "ACCOUNT_LIST",
	"Accounts Create": "ACCOUNT_CREATE",
	"Accounts Edit": "ACCOUNT_EDIT",
	"Service List": "SERVICE_LIST",
	"Service Create": "SERVICE_CREATE",
	"Service Edit": "SERVICE_EDIT",
	"Patient List": "PATIENT_LIST",
	"Patient Name": "PATIENT_N1",
	"Patient ID": "PATIENT_ID",
	"Patient Submit Date": "SUBMIT_DATE",
	"Patient Clinic": "PATIENT_CLINIC",
	"Patient Assigned Doctor": "PATIENT_DOCTOR",
	"Patient Create": "PATIENT_CREATE",
	"Task Completion": "TASK_COMPLETION",
	"Task Select": "TASK_SELECT",
	"Patient Edit": "PATIENT_EDIT",
	"Menu Profile": "MENU_PROFILE",
	"Office Tab": "OFFICE_TAB",
	"Pricing Tab": "PRICING_TAB",
	"Invoicing Tab": "INVOICING_TAB",
	"Payment Tab": "PAYMENT_TAB",
	"Add Office": "ADD_OFFICE",
	"Office List": "OFFICE_LIST",
	"Office Edit": "OFFICE_EDIT",
	"Pricing List": "PRICING_LIST",
	"Add Payment Method": "ADD_PAYMENT_METHOD",
	"View TP": "VIEW_TP",
	"Patient Replace": "PATIENT_REPLACE",
	"Patient Pause": "PATIENT_PAUSE",
	"Patient Transfer": "PATIENT_TRANSFER",
	"Patient Archive": "PATIENT_ARCHIVE",
	"ProfileInfo Update": "PROFILE_INFO_UPDATE",
	"ProfilePhoto Update": "PROFILE_PHOTO_UPDATE",
	"Email Notification": "EMAIL_NOTIFICATION",
	"Treatment Files": "TREATMENT_FILES",
	"Treatment Photo Composite": "TREATMENT_PHOTO_COMPOSITE",
	"Treatment Photo Individual": "TREATMENT_PHOTO_INDIVIDUAL",
	"Task Complete : Botton": "TASK_COMPLETE_BTN",
	"Task Complete : Select": "TASK_COMPLETE_CHCKBTN",
	"Task Complete : Note": "TASK_COMPLETE_NOTE",
	"Task Complete : Rollback": "TASK_COMPLETE_ROLLBACK",
	"Task Complete : Upload file": "TASK_COMPLETE_UPLOAD",
	"Treatment Dropdown": "TREATMENT_DROPDOWN",
	"tx.Instructions : Orthodontist Name": "SIDEBAR_ORTHO",
	"tx.Instructions : Instructions": "SIDEBAR_INSTRUCTIONS",
	"tx.Instructions : Bonding teeths": "SIDEBAR_BONDING_TEETH",
} as const

type GenericPermissionsKeys = keyof typeof GENERIC_PERMISSIONS

export type GENERIC_PERMISSIONS_TYPE = {
	[K in GenericPermissionsKeys]: number | string
}
