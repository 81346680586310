import axios from "axios"
import { constants } from "./CelebApiConstants"

export class CelebRequestGeneratorService {
	static processGetRequest( url: string ) {
		const requestUrl = `${constants.BASE_URL}${constants.API_V1}${url}`
		return axios.get( requestUrl )
	}

	static processPostRequest( url: string, payload: any ): Promise<any> {
		const requestUrl = `${constants.BASE_URL}${constants.API_V1}${url}`

		return axios.post( requestUrl, payload )
	}

	static processPutRequest( url: string, payload: any ) {
		const requestUrl = `${constants.BASE_URL}${constants.API_V1}${url}`

		return axios.put( requestUrl, payload )
	}

	static processDeleteRequest( url: string ) {
		const requestUrl = `${constants.BASE_URL}${constants.API_V1}${url}`

		return axios.delete( requestUrl )
	}
}
