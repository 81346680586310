import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const InvalidLink = () => {
	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
				height: "95vh",
				textAlign: "center",
				color: "#721c24",
			}}
		>
			<FontAwesomeIcon
				icon={faExclamationTriangle}
				style={{
					fontSize: "50px",
					marginBottom: "20px",
				}}
			/>
			<h2
				style={{
					fontSize: "2em",
					margin: "10px 0",
				}}
			>
				Invalid Page
			</h2>
			<p
				style={{
					fontSize: "1.2em",
				}}
			>
				Sorry, the page you're looking is not valid.
			</p>
		</div>
	)
}

export default InvalidLink
