import React from "react"
import { Button, Modal, Nav, Navbar } from "react-bootstrap"
import { FieldErrors, useFormContext } from "react-hook-form"
import { useLocation } from "react-router"
import useCustomErrorMarkerTry from "../../../hooks/patient/customErrorMarker"
import {
	PatientSchemaType,
	ReplaceOrderSchemaType,
	usePatientReplaceMutation,
} from "../../../services/CelebPatient.Service"
import { getPatientTreatmentFilesQuery } from "../../../services/CelebTreatmentFiles.Service"
import { usePatientDialogStore } from "../../../store/patientDailogStore"
import { useTeethStore } from "../../../store/toothSelectionStore"
import { userSessionStore } from "../../../store/userSessionStore"
import { TreatmentFileCategory } from "../../../utils/appConstants"
import { clearHash } from "../../utlity/GenricFunctions"
import ProgressBarLoader from "../../utlity/ProgressBar.Loader"
import { photosLibraryReset } from "../editPatients-v2/defaultFileShowCase"
import { uploadMedicalFiles } from "../patient-v2/PatientNavbar"
import "./Patient.css"
import { fileResult } from "./PatientPhotos.tab"
const PatientNavbar = () => {
	const { setValue, getValues, control } = useFormContext<ReplaceOrderSchemaType>()
	const { customErrorMarker } = useCustomErrorMarkerTry()
	const locationHash = useLocation()
	const clearHashClear = clearHash()
	const {
		replaceOrderPhoto,
		cbctDocumentJson,
		xRays,
		scan,
		navErrors,
		replaceOpen,
		clearState,
		setReplaceOpen,
	} = usePatientDialogStore()
	const { mutateAsync: replaceOrder } = usePatientReplaceMutation()
	const { teethReplacement, resetTeethSelection } = useTeethStore()
	const { setProgressLoaderObj, setProgressLoaderJson } = userSessionStore()
	const { data: defaultTreatmentFiles } = getPatientTreatmentFilesQuery( replaceOpen )
	const additionalApiRequirments = async () => {
		setProgressLoaderObj( "max", 100 )
		setProgressLoaderObj( "now", 0 )
		const doctorAddress = getValues( "selectedOffice" )
		setValue( "office", doctorAddress )
		setValue( "replaceOrder.braces", teethReplacement )
		control.handleSubmit( checkSecondaryFilesValidation, onError )()
	}

	const uploadDocumentsToServer = async () => {
		setProgressLoaderObj( "show", true )
		setProgressLoaderObj( "now", 10 )
		const uploadCbctData = await uploadFiles( cbctDocumentJson, TreatmentFileCategory.PATIENT_CBCT )
		setProgressLoaderObj( "now", 20 )
		const uploadPhotoData = await uploadFiles(
			replaceOrderPhoto,
			TreatmentFileCategory.REPLACE_ORDER_PHOTO,
		)
		setProgressLoaderObj( "now", 30 )
		const uploadXRaysData = await uploadFiles( xRays, TreatmentFileCategory.PATIENT_XRAY )
		const uploadScanData = await uploadFiles( scan, TreatmentFileCategory.PATIENT_SCAN )
		setProgressLoaderObj( "now", 40 )
		setProgressLoaderObj( "now", 50 )
		const treatmentFiles = [
			...uploadPhotoData,
			...uploadCbctData,
			...uploadXRaysData,
			...uploadScanData,
		].filter( ( file ): file is UploadData =>
			file !== undefined && file.uri.length >= 1 ? true : false,
		)
		setProgressLoaderObj( "now", 80 )
		setValue( "treatmentPlan.treatmentFiles", treatmentFiles )
		control.handleSubmit( submit, onError )()
	}

	const uploadFiles = async ( files: { [key: string]: File | null }, category: string ) => {
		const uploadPromises = Object.keys( files ).map( async ( fileKey ) => {
			const file = files[ fileKey ]
			const tempObj = {
				category: "",
				subCategory: "",
				originalFileName: "",
				uri: "",
			}
			if ( file ) {
				return await uploadMedicalFiles( file, category, fileKey )
			} else {
				defaultTreatmentFiles.forEach( ( fileData: fileResult ) => {
					if ( fileData.fileSubCategory == fileKey ) {
						tempObj.category = fileData.fileCategory
						tempObj.subCategory = fileData.fileSubCategory
						tempObj.originalFileName = fileData.originalFileName
						tempObj.uri = fileData.fileUri
					}
				} )
				return tempObj
			}
		} )
		return await Promise.all( uploadPromises )
	}

	const checkSecondaryFilesValidation = ( value: ReplaceOrderSchemaType ) => {
		console.log( customErrorMarker() )
		if ( customErrorMarker() ) return null
		uploadDocumentsToServer()
	}

	const submit = ( value: ReplaceOrderSchemaType ) => {
		replaceOrder( { id: replaceOpen, payload: value } )
		setProgressLoaderJson( {
			max: 100,
			message: "Data Uploaded SuccessFully",
			now: 100,
			show: false,
		} )
		clearFormData()
	}

	const onError = ( errorData: FieldErrors<PatientSchemaType> ) => {
		console.log( errorData )
		customErrorMarker( errorData )
	}

	const clearFormData = () => {
		clearState()
		setReplaceOpen( null )
		resetTeethSelection()
		photosLibraryReset()
		clearHashClear()
	}

	return (
		<React.Fragment>
			<Navbar as={Modal.Header} id="navbarScroll">
				<Nav variant="tabs" defaultActiveKey="#PatientInfo" className="d-flex mt-2 me-auto">
					<Nav.Link
						href="#PatientInfo"
						active={locationHash.hash == "#PatientInfo"}
						className={navErrors.infoTab}
					>
						Patient Replace Info
					</Nav.Link>
					<Nav.Link
						href="#Scan"
						active={locationHash.hash == "#Scan"}
						className={navErrors.scanTab}
					>
						Scan
					</Nav.Link>
					<Nav.Link
						href="#Replace"
						active={locationHash.hash == "#Replace"}
						className={navErrors.replace}
					>
						Replace Order
					</Nav.Link>
					<Nav.Link
						href="#Photos"
						active={locationHash.hash == "#Photos"}
						className={navErrors.photos}
					>
						Photos
					</Nav.Link>
					<Nav.Link href="#X-rays" active={locationHash.hash == "#X-rays"}>
						X-rays
					</Nav.Link>
					<Nav.Link href="#CBCT" active={locationHash.hash == "#CBCT"}>
						CBCT
					</Nav.Link>
				</Nav>
				<span>
					<Button onClick={additionalApiRequirments} className="mx-2">
						Request Replacement
					</Button>
					<Button variant="secondary" onClick={clearFormData}>
						Cancel
					</Button>
				</span>
			</Navbar>
			<ProgressBarLoader />
		</React.Fragment>
	)
}

export type UploadData = {
	category: string
	subCategory: string
	originalFileName: string
	uri: string
}

export default PatientNavbar
