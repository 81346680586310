import { useMutation, useSuspenseQuery } from "@tanstack/react-query"
import axios from "axios"
import { z } from "zod"
import { queryClient } from "../providers/QueryProvider"
import { UserSessionType } from "../store/userSessionStore"
import { constants } from "./CelebApiConstants"

// Minimum 6 characters, at least one uppercase letter, one lowercase letter, one number
const passwordValidation = new RegExp( /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{6,}$/ )

export class CelebAuthService {
	static _instance: CelebAuthService = new CelebAuthService()

	private constructor() {
		CelebAuthService._instance = this
	}

	static getInstance(): CelebAuthService {
		return CelebAuthService._instance
	}

	async login( requestPayload: LoginRequest ) {
		const requestUrl = `${constants.BASE_URL}/auth/login`
		const response = await axios.post( requestUrl, requestPayload )
		const responseData: responseDataType = {
			user: response.data.data.user,
			token: response.data.data.accessToken,
		}
		return responseData
	}

	async forgotpassword( requestPayload: ForgotPasswordRequest ) {
		const requestUrl = `${constants.BASE_URL}${constants.API_V1}${constants.FORGOT_PASSWORD_V1}`
		return await axios.post( requestUrl, requestPayload )
	}

	async validateResetLink( token: string ) {
		const requestUrl = `${constants.BASE_URL}${constants.API_V1}${constants.VALIDATE_PASSWORD_V1}`
		return await axios.get( requestUrl, {
			headers: {
				forgetToken: token,
			},
		} )
	}
	async ResetPasswordLink( token: string, password: string ) {
		const requestUrl = `${constants.BASE_URL}${constants.API_V1}${constants.RESET_PASSWORD_V1}`
		return await axios.post(
			requestUrl,
			{
				password: password,
			},
			{
				headers: {
					forgetToken: token,
				},
			},
		)
	}
}

export const useValidateLinkMuation = ( token: string ) => {
	return useSuspenseQuery( {
		queryKey: [ "token", token ],
		queryFn: () => {
			return CelebAuthService._instance.validateResetLink( token ).then( ( data ) => {
				if ( data.data.message == "Token is valid" && data.status == 200 ) {
					return true
				} else if ( data.data.message == "already reseted password" && data.data.status == 401 ) {
					return "reseted password"
				} else {
					return false
				}
			} )
		},
	} )
}

export const useLoginMutation = () => {
	return useMutation( {
		mutationFn: async ( payload: LoginRequest ) => {
			const response = await CelebAuthService._instance.login( payload )
			return response
		},
		onSuccess: () => {
			queryClient.invalidateQueries()
		},
		onError: ( error ) => {
			console.error( "Login error:", error )
		},
	} )
}

export const useForgotPasswordMuation = () => {
	return useMutation( {
		mutationFn: async ( payload: ForgotPasswordRequest ) => {
			try {
				const response = await CelebAuthService._instance.forgotpassword( payload )
				return response
			} catch ( error ) {
				console.error( "Forgot password request failed:", error )
				throw error
			}
		},
		onSuccess: () => {
			queryClient.invalidateQueries()
		},
		onError: ( error ) => {
			console.error( "Error occurred during forgot password:", error )
		},
	} )
}

export const useForgotResetMuation = () => {
	return useMutation( {
		mutationFn: async ( payload: { token: string; password: string } ) => {
			try {
				const response = await CelebAuthService._instance.ResetPasswordLink(
					payload.token,
					payload.password,
				)
				return response.data
			} catch ( error ) {
				console.error( "Forgot password request failed:", error )
				throw error
			}
		},
		onSuccess: () => {
			queryClient.invalidateQueries()
		},
		onError: ( error ) => {
			console.error( "Error occurred during forgot password:", error )
		},
	} )
}
export const LoginRequestSchema = z.object( {
	email: z.string().email(),
	password: z.string().min( 2 ),
} )

export const ForgotPasswordSchema = z.object( {
	email: z.string().email(),
	password: z.string().optional(),
} )

export const ResetPasswordSchema = z
	.object( {
		password: z
			.string()
			.min( 6, "Password must be at least 6 characters, 1 symbol, 1 number" )
			.regex( passwordValidation, {
				message: "Your password is not valid",
			} ),
		confirmPassword: z.string().min( 6, "Confirm password is required" ).regex( passwordValidation, {
			message: "Your password is not valid",
		} ),
	} )
	.refine( ( data ) => data.password === data.confirmPassword, {
		message: "Passwords don't match",
		path: [ "confirmPassword" ],
	} )

// export const validateResetLinkSchema = z.object( {
// 	validationId: z.string(),
// 	email: z.string().email( "Invalid email address" ),
// } )

// export type validateResetLink = z.infer<typeof validateResetLinkSchema>
export type ResetPasswordRequest = z.infer<typeof ResetPasswordSchema>
export type LoginRequest = z.infer<typeof LoginRequestSchema>
export type ForgotPasswordRequest = z.infer<typeof ForgotPasswordSchema>
export type responseDataType = {
	user: UserSessionType
	token: string
}
