import { useMutation, useSuspenseQuery } from "@tanstack/react-query"
import axios from "axios"
import { z } from "zod"
import { sortForReactSelect } from "../components/utlity/GenricFunctions"
import { queryClient } from "../providers/QueryProvider"
import { usePatientDialogStore } from "../store/patientDailogStore"
import { userSessionStore } from "../store/userSessionStore"
import { constants } from "./CelebApiConstants"
import { CelebRequestGeneratorService } from "./CelebRequestGenerator.Service"
import { SpecialRoleEnumSchema } from "./CelebRole.Service"

export class CelebUserService {
	static _instance: CelebUserService = new CelebUserService()

	private constructor() {
		CelebUserService._instance = this
	}

	static getInstance(): CelebUserService {
		return CelebUserService._instance
	}

	async getAllUsers( pageOffset?: number, query?: string, limit?: number ) {
		let queryParam = `?offset=${pageOffset ?? 0}`
		if ( query && query.length > 0 ) {
			queryParam += `&search=${query}`
		}
		if ( limit ) {
			queryParam += `&limit=${limit}`
		}
		const requestUrl = `${constants.BASE_URL}${constants.API_V1}${constants.USERS}${queryParam}`

		return await axios.get( requestUrl )
	}

	async getAllSubUsers() {
		const requestUrl = `${constants.USERS}/me/subusers`

		return await CelebRequestGeneratorService.processGetRequest( requestUrl )
	}

	async createUser( requestPayload: CreateUserType ) {
		const requestUrl = `${constants.BASE_URL}${constants.API_V1}${constants.USERS}`

		return await axios.post( requestUrl, requestPayload )
	}

	static async editAccountInfo( requestPayload: UserProfileType ) {
		return await CelebRequestGeneratorService.processPutRequest(
			constants.USERS + "/me",
			requestPayload,
		)
	}

	static async getAccountInfo() {
		return await CelebRequestGeneratorService.processGetRequest( constants.USERS + "/me" )
	}
	async deleteuser( id: string ) {
		const requestUrl = `${constants.BASE_URL}${constants.API_V1}${constants.USERS}/${id}`

		return await axios.delete( requestUrl )
	}

	async editUserDetails( payload: EditUserType ) {
		const requestUrl = `${constants.BASE_URL}${constants.API_V1}${constants.USERS}/${payload.id}`

		return await axios.put( requestUrl, payload )
	}

	async sendPasswordResetLink( id: string ) {
		const requestUrl = `${constants.BASE_URL}${constants.API_V1}${constants.USERS}/resetpassword/link/${id}`
		return await axios.put( requestUrl )
	}

	async sendPasswordResetAdmin( payload: { id: string; password: string } ) {
		const requestUrl = `${constants.BASE_URL}${constants.API_V1}${constants.USERS}/resetpassword/manual/${payload.id}`
		return await axios.post( requestUrl, { password: payload.password } )
	}

	async getDoctorsList() {
		const requestUrl = `${constants.USERS}${constants.DOCTOR}`
		return await CelebRequestGeneratorService.processGetRequest( requestUrl )
	}

	async getDoctorsSearchList( search: string ) {
		const requestUrl = `${constants.USERS}${constants.DOCTOR}/search/${search}`
		return await CelebRequestGeneratorService.processGetRequest( requestUrl )
	}

	async logoutUser() {
		return await axios.get( `${constants.BASE_URL}/auth/logout` )
	}
}

export const useDoctorListQuery = () => {
	const { setDoctorList } = usePatientDialogStore()
	return useSuspenseQuery( {
		queryKey: [ "Doctorlist", `${constants.USERS}${constants.DOCTOR}` ],
		queryFn: () =>
			CelebUserService.getInstance()
				.getDoctorsList()
				.then( ( res ) => ( { data: res.data.data } ) )
				.then( ( { data } ) => {
					setDoctorList( sortForReactSelect( data, "id", "firstName", "lastName" ) )
					return {
						data: data,
					}
				} ),
	} )
}

export const useGetAllSubUsers = () =>
	useSuspenseQuery( {
		queryKey: [ "Doctorlist", `${constants.USERS}/me/subusers` ],
		queryFn: () =>
			CelebUserService.getInstance()
				.getAllSubUsers()
				.then( ( res ) => ( { data: res.data.data } ) )
				.then( ( { data } ) => {
					return {
						data: data,
					}
				} ),
	} )

export const listUserQueryKeyFn = ( props?: UseListUsersQueryProps ) =>
	[ "list", constants.USERS, props ].filter( Boolean )

export const useListUsersQuery = ( props?: UseListUsersQueryProps ) =>
	useSuspenseQuery( {
		queryKey: [ "list", constants.USERS, props ],
		queryFn: () =>
			CelebUserService.getInstance()
				.getAllUsers( props?.pageOffset, props?.query, props?.limit )
				.then( ( res ) => ( { data: res.data.data, total: res.headers[ "x-total-count" ] } ) )
				.then( ( { data, total } ) => {
					return {
						data: data,
						total: total,
					}
				} ),
	} )

export type UseListUsersQueryProps = {
	pageOffset?: number
	query?: string
	limit?: number
}

export const useCreateUserMutation = () =>
	useMutation( {
		mutationFn: ( payload: CreateUserType ) => CelebUserService.getInstance().createUser( payload ),
		onSuccess: () => {
			return queryClient.invalidateQueries( { queryKey: listUserQueryKeyFn(), exact: false } )
		},
	} )

export const useEditAccountInfoMutation = () =>
	useMutation( {
		mutationFn: ( payload: UserProfileType ) => CelebUserService.editAccountInfo( payload ),
		onSuccess: ( response ) => {
			console.log( response )
			return queryClient.invalidateQueries( { queryKey: listUserQueryKeyFn(), exact: false } )
		},
	} )

export const useDeleteUserMutation = () =>
	useMutation( {
		mutationFn: ( payload: DeleteUserType ) => CelebUserService.getInstance().deleteuser( payload.id ),
		onSuccess: () => {
			return queryClient.invalidateQueries( { queryKey: listUserQueryKeyFn(), exact: false } )
		},
	} )

export const useEditUserMutation = () =>
	useMutation( {
		mutationFn: ( payload: EditUserType ) => CelebUserService.getInstance().editUserDetails( payload ),
		onSuccess: () => {
			return queryClient.invalidateQueries( { queryKey: listUserQueryKeyFn(), exact: false } )
		},
	} )

export const useResetPasswordMutation = () => {
	const { setShowToastJson } = userSessionStore()
	return useMutation( {
		mutationFn: ( payload: any ) => CelebUserService.getInstance().sendPasswordResetLink( payload.id ),
		onSuccess: ( data ) => {
			console.log( "data,", data )
			alert( data )
			setShowToastJson( {
				visible: true,
				status: "Success",
				message: "Password Reset link Sucessfully sent to user's mail Id",
			} )
			return queryClient.invalidateQueries( { queryKey: listUserQueryKeyFn(), exact: false } )
		},
		onError: ( data ) => {
			console.log( "data,", data )
		},
	} )
}

export const useResetPasswordAdminMutation = () => {
	const { setShowToastJson } = userSessionStore()
	return useMutation( {
		mutationFn: ( payload: any ) => CelebUserService.getInstance().sendPasswordResetAdmin( payload ),
		onSuccess: ( data ) => {
			console.log( "data,", data )
			alert( data )
			setShowToastJson( {
				visible: true,
				status: "Success",
				message: "Password Reset Sucessfully",
			} )
			return queryClient.invalidateQueries( { queryKey: listUserQueryKeyFn(), exact: false } )
		},
		onError: ( data ) => {
			console.log( "data,", data )
		},
	} )
}

export const useLogoutMutation = () => {
	return useMutation( {
		mutationFn: () => CelebUserService.getInstance().logoutUser(),
		onSuccess: () => queryClient.invalidateQueries(),
	} )
}

export type DeleteUserType = {
	id: string
}

export const UserRoleSchema = z.object( {
	id: z.string(),
	name: z.string(),
} )

export const UserSchema = z.object( {
	id: z.string(),
	entityId: z.number(),
	firstName: z.string(),
	lastName: z.string(),
	email: z.string().email(),
	contactNumber: z.string(),
	password: z.string(),
	description: z.string().nullable(),
	profilePictureUri: z.string().nullable(),
	status: z.boolean(),
	clinicalPreference: z.string().nullable(),
	emailNotificationsSubscribed: z.boolean(),
	resetPasswordCode: z.string().nullable(),
	lastPermissionUpdatedOn: z.null(),
	isSuperAdmin: z.boolean().nullable(),
	specialRole: SpecialRoleEnumSchema.nullable(),
	deleted: z.boolean(),
	deletedAt: z.string().datetime().nullable(),
	deletedBy: z.string().nullable(),
	parentAccountId: z.string().nullable(),
	roleId: z.string(),
	createdAt: z.string().datetime(),
	updatedAt: z.string().datetime(),
	parentAccount: z.object( {
		firstName: z.string().nullable(),
		lastName: z.string().nullable(),
		id: z.string().nullable(),
		email: z.string().nullable(),
	} ),
	role: UserRoleSchema,
} )

export type UserType = z.infer<typeof UserSchema>

export const CreateServicePricingSchema = z.object( {
	serviceId: z.string().min( 1 ),
	priceUpper: z.number().default( 0 ).optional(),
	priceLower: z.number().default( 0 ).optional(),
	priceBoth: z.number().default( 0 ).optional(),
} )

export type CreateServicePricingType = z.infer<typeof CreateServicePricingSchema>

export const CreateUserSchema = z.object( {
	firstName: z
		.string( { message: "Please enter a valid First Name" } )
		.trim()
		.min( 1, "First Name is mandotory" ),
	lastName: z
		.string( { message: "Please enter a valid Last Name" } )
		.trim()
		.min( 1, "Last Name is mandotory" ),
	email: z
		.string( { message: "Please enter a valid Email" } )
		.min( 1, "Email is mandatory" )
		.email( { message: "Please enter a valid email address" } )
		.max( 100, "Email must be less than 100 characters" )
		.refine( ( value ) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test( value ), {
			message: "Invalid email format",
		} )
		.transform( ( value ) => value.toLowerCase() ),
	contactNumber: z
		.string( { message: "Phone number is mandatory" } )
		.min( 1, "Phone number is mandatory" ),
	status: z.boolean().default( false ),
	roleId: z
		.string()
		.nullable()
		.refine( ( value ) => value !== null && value !== "", { message: "Please select user type" } ),
	salesRepId:
		z.array( z.any() ).optional() || z.array( z.object( { value: z.string(), label: z.string() } ) ),
	parentAccountId: z
		.string()
		.transform( ( v ) => ( v.trim().length === 0 ? undefined : v ) )
		.optional(),
	servicePricings: CreateServicePricingSchema.array().optional(),
} )

export type CreateUserType = z.infer<typeof CreateUserSchema>

export const EditUserSchema = CreateUserSchema.extend( {
	id: z.string(),
} )

export type EditUserType = z.infer<typeof EditUserSchema>

export const UserProfileSchema = z.object( {
	id: z.string(),
	firstName: z.string().trim().min( 1, { message: "Please Enter Name" } ),
	lastName: z.string().trim().min( 1, { message: "Please Enter Name" } ),
	roleId: z.string(),
	profilePictureUri: z.any().optional(),
	specialRole: z.string(),
	isAdmin: z.boolean(),
	isDoctor: z.boolean(),
	parentAccountId: z.string().nullable(),
	clinicalPreference: z.string().optional().nullable(),
	lastPermissionUpdatedOn: z.union( [ z.string(), z.number(), z.null() ] ),
	genericPermissions: z.any().optional(),
	genericPermissionsRead: z.array( z.unknown() ),
	genericPermissionsWrite: z.array( z.unknown() ),
	taskPermissions: z.object( {} ),
	taskPermissionsRead: z.array( z.unknown() ),
	taskPermissionsWrite: z.array( z.unknown() ),
	emailNotificationsSubscribed: z.boolean().default( false ).optional(),
} )

export type UserProfileType = z.infer<typeof UserProfileSchema>

export interface GetAccountInfoOkResponse {
	status: number
	message: string
	data: {
		id: string
		entityId: number
		firstName: string
		lastName: string
		email: string
		contactNumber: any
		password: string
		description: any
		profilePictureUri: any
		status: boolean
		clinicalPreference: any
		emailNotificationsSubscribed: boolean
		resetPasswordCode: any
		lastPermissionUpdatedOn: any
		isSuperAdmin: boolean
		specialRole: string
		deleted: boolean
		deletedAt: any
		deletedBy: any
		parentAccountId: any
		roleId: string
		createdAt: string
		updatedAt: string
	}
}
