import React, { useEffect } from "react"
import { userSessionStore } from "../store/userSessionStore"

const RouterAuth = ( { children }: { children: React.ReactNode } ) => {
	const setSession = userSessionStore( ( state: any ) => state.setSession )
	useEffect( () => {
		window.addEventListener( "storage", function ( event ) {
			window.location.reload()
			window.localStorage.clear()
		} )
		GetToken()
	}, [ children ] )

	const GetToken = () => {
		const UserCheck: string | null = localStorage.getItem( "user" )
		const jwttoken: string | null = localStorage.getItem( "token" )

		const parseduserCheck = UserCheck ? JSON.parse( UserCheck ) : ( window.location.href = "/login" )
		setSession( parseduserCheck )
		if ( jwttoken == null || undefined ) {
			return ( window.location.href = "/login" )
		}
		if ( parseduserCheck.isAdmin == false && window.location.pathname.includes( "managment" ) ) {
			return ( window.location.href = "/Error/:managment" )
		}
		if ( parseduserCheck.isDoctor == false && window.location.pathname.includes( "/doctor" ) ) {
			return ( window.location.href = "/Error/:doctor" )
		}
		if (
			( parseduserCheck.isAdmin == true || parseduserCheck.isDoctor == true ) &&
			window.location.pathname.includes( "/operator" )
		) {
			return ( window.location.href = "/Error/:operator" )
		}
		if ( parseduserCheck.roleId == null || undefined ) {
			return ( window.location.href = "/login" )
		}
	}
	return <>{children}</>
}

export default RouterAuth
