import React from "react"
import { Nav } from "react-bootstrap"
import { useNavigate, useSearchParams } from "react-router-dom"
import { getPatientByIdQuery } from "../../../services/CelebTreatment.Service"
import { useSidebarStore } from "../../../store/sideBarStore"
import { photosLibraryReset } from "../../dialogs/editPatients-v2/defaultFileShowCase"
import "./sidebar.css"

const NavbarNavigation = () => {
	const sidebar = useSidebarStore()
	const [ searchParams, setSearchParams ] = useSearchParams()
	const navigate = useNavigate()
	const patientId = searchParams.get( "PatientId" )
	const activeTab = sidebar.sideBarPatientData.activeTab
	const { data: defaultValuesEdit } = getPatientByIdQuery( patientId ?? "null" )
	React.useEffect( () => {
		setSideBarData( "PatientInfo" )
	}, [ defaultValuesEdit ] )
	const setSideBarData = ( info: string ) => {
		sidebar.setSideBarPatientData( {
			activeTab: info,
			PatientData: defaultValuesEdit,
		} )
		photosLibraryReset()
	}

	return (
		<Nav variant="tabs" className="sidebar border-0" activeKey={activeTab}>
			<Nav.Item
				className={activeTab === "PatientInfo" ? "active" : ""}
				onClick={() => setSideBarData( "PatientInfo" )}
			>
				Info
			</Nav.Item>
			<Nav.Item
				className={activeTab === "Timeline" ? "active" : ""}
				onClick={() => setSideBarData( "Timeline" )}
			>
				Timeline
			</Nav.Item>
			<Nav.Item
				className={activeTab === "Records" ? "active" : ""}
				onClick={() => setSideBarData( "Records" )}
			>
				Records
			</Nav.Item>
			<Nav.Item
				className={activeTab === "Financials" ? "active" : ""}
				onClick={() => setSideBarData( "Financials" )}
			>
				Financials
			</Nav.Item>
			<Nav.Item
				className={activeTab === "TaxInstruction" ? "active" : ""}
				onClick={() => setSideBarData( "TaxInstruction" )}
			>
				Tx.Instruction
			</Nav.Item>
			{sidebar?.sideBarPatientData?.PatientData?.isReplacementOrder && (
				<Nav.Item
					className={activeTab === "Order" ? "active" : ""}
					onClick={() => setSideBarData( "Order" )}
				>
					Order
				</Nav.Item>
			)}
		</Nav>
	)
}

export default NavbarNavigation
