import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome"
import { Dropdown } from "react-bootstrap"

export const EditTrash = ( {
	editCallback,
	deleteCallBack,
	editIcon,
	trashIcon,
	editShow = true,
	trashShow = true,
	dropdownShow = false,
	dropdownOptions,
}: EditTrashProps ) => {
	return (
		<span className="d-flex align-items-center">
			{editShow && (
				<FontAwesomeIcon {...editIcon} className="celebActionIcon mx-2" onClick={editCallback} />
			)}
			{trashShow && (
				<FontAwesomeIcon {...trashIcon} className="celebActionIcon mx-2" onClick={deleteCallBack} />
			)}

			{dropdownShow && (
				<Dropdown>
					<Dropdown.Toggle
						variant="success"
						id="dropdown-basic"
						style={{ border: "none", boxShadow: "none" }}
						className="dropdown-button"
					></Dropdown.Toggle>
					<Dropdown.Menu>
						{dropdownOptions &&
							dropdownOptions.map( ( x ) => (
								<Dropdown.Item onClick={x.cb} data-selected={x}>
									{x.label}
								</Dropdown.Item>
							) )}
					</Dropdown.Menu>
				</Dropdown>
			)}
		</span>
	)
}

type EditTrashProps = {
	editCallback?: () => void
	deleteCallBack?: () => void
	trashIcon: FontAwesomeIconProps
	editIcon: FontAwesomeIconProps
	editShow?: boolean
	trashShow?: boolean
	dropdownShow?: boolean
	dropdownOptions?: { label: string; cb: ( e: React.MouseEvent<HTMLElement, MouseEvent> ) => void }[]
}
