import React from "react"
import { Form, FormControlProps } from "react-bootstrap"
import { FieldPath, get, useFormContext } from "react-hook-form"
import { AddressSchemaType } from "../../../services/CelebDoctorAddress.Service"
import { UserSessionType } from "../../../store/userSessionStore"

export const ProfileFormInput = ( { name, label, required, ...props }: ProfileFormInput ) => {
	const { register, formState: errors } = useFormContext<UserSessionType>()

	return (
		<React.Fragment>
			<Form.Group>
				<Form.Label>
					{label}
					{required ? <span style={{ color: "red" }}>*</span> : ""}
				</Form.Label>
				<Form.Control
					{...props}
					{...register( name )}
					required
					isInvalid={get( errors.errors, name )}
				/>
				<p className="text-danger">
					{get( errors.errors[ name ], name )} {errors.errors[ name ]?.message}
				</p>
				<Form.Control.Feedback type="invalid">
					{get( errors.errors[ name ], name )}
				</Form.Control.Feedback>
			</Form.Group>
		</React.Fragment>
	)
}

export const AddressFormInput = ( { name, label, required, ...props }: AddressFormInput ) => {
	const { register, formState: errors } = useFormContext<AddressSchemaType>()

	return (
		<React.Fragment>
			<Form.Group>
				<Form.Label>
					{label}
					{required ? <span style={{ color: "red" }}>*</span> : ""}
				</Form.Label>
				<Form.Control {...props} {...register( name )} isInvalid={get( errors.errors, name )} />
				<p className="text-danger">
					{get( errors.errors[ name ], name )} {errors.errors[ name ]?.message}
				</p>
				<Form.Control.Feedback type="invalid">
					{get( errors.errors[ name ], name )}
				</Form.Control.Feedback>
			</Form.Group>
		</React.Fragment>
	)
}
export type ProfileFormInput = Omit<FormControlProps, "name"> & {
	name: FieldPath<UserSessionType>
	label?: string
	required?: boolean
}

export type AddressFormInput = Omit<FormControlProps, "name"> & {
	name: FieldPath<AddressSchemaType>
	label?: string
	required?: boolean
}
