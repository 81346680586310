import { faPencil, faTrash } from "@fortawesome/free-solid-svg-icons"
import React, { Suspense } from "react"
import { Col, Modal, Nav, Navbar, Row, Table } from "react-bootstrap"
import { getSalesRepresentativesApi } from "../../../services/CelebSalesRepresentative.service"
import { useSalesModalStore } from "../../../store/salesDialogStore"
import { EditTrash } from "../../utlity/EditTrash"

const SalesDialog = () => {
	const show = useSalesModalStore( ( state ) => state.showSalesModal )
	const setShow = useSalesModalStore( ( state ) => state.setModalOpen )
	return (
		<Suspense>
			<Modal show={show} onHide={() => setShow( false )} size="xl" backdrop="static">
				<SalesDialogImp />
			</Modal>
		</Suspense>
	)
}

const SalesDialogImp = () => {
	const {
		data: { data: listSalesRepresentatives },
	} = getSalesRepresentativesApi( { role: "sales" } )
	const salesStore = useSalesModalStore()
	return (
		<React.Fragment>
			<Navbar style={{ backgroundColor: "#d9d9d9" }} as={Modal.Header} closeButton>
				<Nav>
					<Nav.Link className="nav-link-opposite mt-1" active={true}>
						Sales
					</Nav.Link>
				</Nav>
			</Navbar>
			<Modal.Body>
				<Row>
					<Col>
						<Table bordered striped className="mt-4" hover>
							<thead>
								<tr>
									<th>Acc ID</th>
									<th>First Name</th>
									<th>Last Name</th>
									<th>Email</th>
									<th>Phone</th>
									<th>Status</th>
									<th>Role</th>
									<th>Parent account</th>
									<th>Actions</th>
								</tr>
							</thead>
							<tbody>
								{listSalesRepresentatives.map( ( user: any ) => {
									return (
										<tr key={user.id}>
											<td>{user.entityId}</td>
											<td>{user.firstName}</td>
											<td>{user.lastName}</td>
											<td>{user.email}</td>
											<td>{user.contactNumber}</td>
											<td>{user.status ? "Active" : " Inactive"}</td>
											<td>{user.role.name}</td>
											<td>
												{user.parentAccountId
													? user.parentAccount.firstName + " " + user.parentAccount.lastName
													: "-"}
											</td>
											<td className="text-right">
												<EditTrash
													editIcon={{ icon: faPencil, color: "#8faadc" }}
													trashIcon={{ icon: faTrash, color: "#717273" }}
													editShow={false}
													trashShow={false}
													dropdownShow={true}
													dropdownOptions={[
														{
															label: "Show Associated Doctors",
															cb: ( e ) =>
																salesStore.setShowAsscociateDoctorsModal( { userId: user.id } ),
														},
													]}
												/>
											</td>
										</tr>
									)
								} )}
							</tbody>
						</Table>
					</Col>
					{/* <Pagination
						total={total}
						pageSize={constants.PAGE_COUNT}
						onChange={handlePagination}
						align={"center"}
					/> */}
				</Row>
			</Modal.Body>
		</React.Fragment>
	)
}

export default SalesDialog
