import { useSuspenseQuery } from "@tanstack/react-query"
import { z } from "zod"
import { constants } from "./CelebApiConstants"
import { CelebRequestGeneratorService } from "./CelebRequestGenerator.Service"
export class CelebUserServicepricingsService {
	static _instance: CelebUserServicepricingsService = new CelebUserServicepricingsService()

	private constructor() {
		CelebUserServicepricingsService._instance = this
	}

	static getInstance(): CelebUserServicepricingsService {
		return CelebUserServicepricingsService._instance
	}

	async getMyPricings() {
		return await CelebRequestGeneratorService.processGetRequest(
			`${constants.USER_SERVICE_PRICINGS}/me`,
		)
	}
}

export const listPricingsQueryKeyFn = () =>
	[ "ListPricings", constants.USER_SERVICE_PRICINGS ].filter( Boolean )

export const useListMyPricings = () => {
	return useSuspenseQuery( {
		queryKey: [ "ListPricings", constants.USER_SERVICE_PRICINGS ],
		queryFn: () =>
			CelebUserServicepricingsService.getInstance()
				.getMyPricings()
				.then( ( res ) => ( { data: res.data.data } ) )
				.then( ( { data } ) => {
					return {
						pricings: data,
					}
				} ),
	} )
}

export const DoctorPricingSchema = z.object( {
	id: z.string(),
	priceUpper: z.number(),
	priceLower: z.number(),
	priceBoth: z.number(),
	doctorId: z.string(),
	serviceId: z.string(),
	createdAt: z.string(),
	updatedAt: z.string(),
	service: z.object( { name: z.string(), id: z.string(), entityId: z.number() } ),
} )

export type DoctorPricingSchemaType = z.infer<typeof DoctorPricingSchema>
