import { faCaretDown, faCaretUp, faSort } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "react"
import { userSessionStore } from "../../store/userSessionStore"

const SortButton = ( { sortKey, onSortChange }: SortButtonProps ) => {
	const [ sortState, setSortState ] = useState<"asc" | "desc" | undefined>( undefined )
	const { sortOn, setSortOn } = userSessionStore()
	const apiParams = sortKey.split( "#" )

	useEffect( () => {
		if ( sortOn !== sortKey ) {
			setSortState( undefined )
		}
	}, [ sortOn, sortKey ] )

	const handleSortClick = () => {
		const nextSortState: "asc" | "desc" | undefined =
			sortState === undefined ? "asc" : sortState === "asc" ? "desc" : undefined
		setSortState( nextSortState )
		setSortOn( sortKey )
		onSortChange( apiParams, nextSortState )
	}

	return (
		<span className={`sort ${sortOn === sortKey ? "active" : ""}`} onClick={handleSortClick}>
			{iconMap[ sortState ?? "undefined" ]}
		</span>
	)
}

interface SortButtonProps {
	sortKey: string
	onSortChange: ( apiParams: string[], sortOrder: "asc" | "desc" | undefined ) => void
}

const iconMap: Record<string, JSX.Element> = {
	asc: <FontAwesomeIcon className="sort active" icon={faCaretUp} />,
	desc: <FontAwesomeIcon className="sort active" icon={faCaretDown} />,
	undefined: <FontAwesomeIcon className="sort" icon={faSort} />,
}

export default SortButton
