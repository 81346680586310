import React from "react"
import { useSearchParams } from "react-router-dom"
import LoadingOverlay from "../../../assets/styles/components/loader/loadingOverlay"
import CompletedTable from "../../../components/dashboard/CompletedTable"
import TasksTable from "../../../components/dashboard/TasksTable"
import SearchTable from "../../../components/dashboard/SearchTable"

const OperatorlandingPage = () => {
	return (
		<React.Suspense >
			<LandingPageImpl />
		</React.Suspense>
	)
}

const LandingPageImpl = () => {
	return <React.Fragment>{RenderLandingPage()}</React.Fragment>
}

const RenderLandingPage = () => {
	const [ searchParams, setSearchParams ] = useSearchParams()
	const status = searchParams.get( "status" )
	if(searchParams.get( "searchPatient" ))return <SearchTable></SearchTable>
	switch ( status ) {
		case "completed":
		case "paused":
		case "archived":
			return <CompletedTable />
		default:
			return <TasksTable />
	}
}

export default OperatorlandingPage
