import { faCheck, faClose } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon as FA } from "@fortawesome/react-fontawesome"
import { Form, FormCheckProps, FormControlProps } from "react-bootstrap"
import { get, useFormContext } from "react-hook-form"
import { z } from "zod"
import { ServiceSchemaForm, ServiceTaskForm } from "../../../services/CelebService.Service"
import { TaskCategory } from "../../../utils/appConstants"
export const ServiceFormInput = ( { name, label, required, ...props }: ServiceInputProps ) => {
	const { register, formState: errors } = useFormContext<ServiceFormType>()
	return (
		<Form.Group>
			<Form.Label>
				{label}
				{required && <span style={{ color: "red" }}>*</span>}
			</Form.Label>
			<Form.Control {...props} isInvalid={get( errors.errors, name )} {...register( name )} />
			<p className="text-danger">
				{get( errors.errors[ name ], name )} {errors.errors[ name ]?.message}
			</p>
		</Form.Group>
	)
}

export const ServiceFormCheckBox = ( { label, required, error, ...props }: ServiceCheckProps ) => {
	return (
		<Form.Group>
			<Form.Label>
				{label}
				{required && <span style={{ color: "red" }}>*</span>}
			</Form.Label>
			<Form.Check {...props} />
			{error}
		</Form.Group>
	)
}

export const ServiceFormTaskInput = ( {
	name,
	label,
	required,

	...props
}: ServiceTaskProps ) => {
	const { register, formState: errors } = useFormContext<ServiceTaskFormType>()
	return (
		<Form.Group>
			<Form.Label>
				{label}
				{required && <span style={{ color: "red" }}>*</span>}
			</Form.Label>
			<Form.Control {...props} isInvalid={get( errors.errors, name )} {...register( name )} />
			<p className="text-danger" style={{ position: "absolute" }}>
				{get( errors.errors[ name ], name )} {errors.errors[ name ]?.message}
			</p>
		</Form.Group>
	)
}

export const CreatableSelectEdit = ( {
	closeCallback,
	editCallback,
	...props
}: CreatableSelectEditProps ) => {
	return (
		<div className="d-flex align-items-center w-100">
			<div className="w-100" style={{ border: "1px solid #dee2e6", borderRadius: "5px" }}>
				<Form.Control {...props} className="customForm"></Form.Control>
			</div>
			<div className=" d-flex align-items-center" style={{ height: "20px" }}>
				<FA
					icon={faClose}
					style={{ fontSize: "1.7rem" }}
					color="#c3c4c3"
					onClick={closeCallback}
					className="mx-2"
				/>
				<span className="vr"></span>
				<FA
					icon={faCheck}
					style={{ fontSize: "1.7rem" }}
					color="#9dfc9e"
					onClick={editCallback}
					className="mx-2"
				/>
			</div>
		</div>
	)
}

export const BoundSelect = ( { name, label, required, options, placeholder }: BountSelectProps ) => {
	const methods = useFormContext<ServiceTaskFormType>()
	const fieldProps = methods.register( name )
	return (
		<Form.Group>
			{typeof label === "string" && (
				<Form.Label>
					{label}
					{required && <span style={{ color: "red" }}>*</span>}
				</Form.Label>
			)}
			<Form.Select size="sm" {...fieldProps} isInvalid={get( methods.formState.errors, name )}>
				{typeof placeholder === "string" && (
					<option value="" selected>
						{placeholder}
					</option>
				)}
				{options.map( ( opt ) => (
					<option key={opt.value} value={opt.value}>
						{opt.label}
					</option>
				) )}
			</Form.Select>
			<Form.Control.Feedback type="invalid" style={{ position: "absolute" }}>
				{get( methods.formState.errors, name )?.message}
			</Form.Control.Feedback>
		</Form.Group>
	)
}

export type BountSelectProps = {
	name: keyof ServiceTaskFormType
	options: SelectOption[]
	placeholder?: string
	label?: string
	required?: boolean
}

export type SelectOption = { value: string; label: string; onSelect?: ( value: string ) => void }

interface CreatableSelectEditProps extends FormControlProps {
	closeCallback?: () => void
	editCallback?: () => void
	categoryFor?: TaskCategory.PROVIDER | TaskCategory.OTHERS
}

export type ServiceCheckProps = Omit<FormCheckProps, "name"> & {
	name?: keyof ServiceFormType
	label?: string
	required?: boolean
	error?: string
}
export type ServiceFormType = z.infer<typeof ServiceSchemaForm>

export type ServiceInputProps = Omit<FormControlProps, "name"> & {
	name: keyof ServiceFormType
	label?: string
	required?: boolean
	error?: string
}

export type ServiceTaskProps = Omit<FormControlProps, "name"> & {
	name: keyof ServiceTaskFormType
	label?: string
	required?: boolean
	error?: string
}

export type ServiceCategoryInputProps = Omit<FormControlProps, "name"> & {
	name: keyof ServiceTaskFormType
	label?: string
	required?: boolean
	error?: string
}

export type ServiceTaskFormType = z.infer<typeof ServiceTaskForm>
