import { zodResolver } from "@hookform/resolvers/zod"
import React from "react"
import { Alert, Modal } from "react-bootstrap"
import { FormProvider, useForm } from "react-hook-form"
import { Params, useLocation, useParams } from "react-router"
import { PatientSchema, PatientSchemaType } from "../../../services/CelebPatient.Service"
import { usePatientDialogStore } from "../../../store/patientDailogStore"
import PatientScanTab from "../patient-v2/PatientScan.tab"
import PatientTreatmentInstructionTab from "../patient-v2/PatientTreatmentInstructions.tab"

import { useTeethStore } from "../../../store/toothSelectionStore"
import { clearHash } from "../../utlity/GenricFunctions"
import { photosLibraryReset } from "../editPatients-v2/defaultFileShowCase"
import PatientCbctTab from "./PatientCBCT.tab"
import PatientInfoTab from "./PatientInfo.tab"
import PatientNavbar from "./PatientNavbar"
import PatientPhotosTab from "./PatientPhotos.tab"
import PatientXrayTab from "./PatientXray.tab"

const PatientAddDialog = () => {
	const PatientDialog = usePatientDialogStore()
	const clearHashClear = clearHash()
	const teethStore = useTeethStore()
	const methods = useForm<PatientSchemaType>( {
		resolver: zodResolver( PatientSchema ),
		mode: "all",
	} )
	const paramsData: Readonly<Params<string>> = useParams()
	
	methods.setValue( "treatmentPlan.serviceId", paramsData.id! )
	const closeForm = () => {
		methods.reset()
		teethStore.resetTeethSelection()
		PatientDialog.clearState()
		PatientDialog.setCreateOpen( false )
		photosLibraryReset()
		clearHashClear()
		PatientDialog.setIsUploadFromScanner( null )
	}

	return (
		<React.Fragment>
			<FormProvider {...methods}>
				<Modal show={PatientDialog.createOpen} onHide={closeForm} size="xl" backdrop="static">
					<PatientNavbar />
					<Alert
						className="m-2"
						variant="warning"
						hidden={!PatientDialog.errorToShow.createPatient}
					>
						{PatientDialog.errorToShow.createPatient}
					</Alert>
					<PatientAddImpl />
				</Modal>
			</FormProvider>
		</React.Fragment>
	)
}

const PatientAddImpl = () => {
	return (
		<React.Fragment>
			<main className="p-3">
				<ActiveTabExporter />
			</main>
		</React.Fragment>
	)
}

const ActiveTabExporter = () => {
	const location = useLocation()

	switch ( location.hash ) {
		case "#PatientInfo":
			return <PatientInfoTab />
		case "#Scan":
			return <PatientScanTab />
		case "#Instructions":
			return <PatientTreatmentInstructionTab />
		case "#Photos":
			return <PatientPhotosTab />
		case "#X-rays":
			return <PatientXrayTab />
		case "#CBCT":
			return <PatientCbctTab />
		default:
			return <PatientInfoTab />
	}
}
export default PatientAddDialog
