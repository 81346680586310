import { z } from "zod"
import { create } from "zustand"

interface SalesModalState {
	showSalesModal: boolean
	setModalOpen: ( next: boolean ) => void
	showAsscociateDoctorsModal: showAsscociateDoctorsForType
	setShowAsscociateDoctorsModal: ( next: showAsscociateDoctorsForType ) => void
}

export const useSalesModalStore = create<SalesModalState>( ( set ) => ( {
	showSalesModal: false,
	setModalOpen: ( next: boolean ) => set( { showSalesModal: next } ),

	showAsscociateDoctorsModal: { userId: "" },
	setShowAsscociateDoctorsModal: ( next: showAsscociateDoctorsForType ) =>
		set( { showAsscociateDoctorsModal: next } ),
} ) )

const showAsscociateDoctorsFor = z.object( { userId: z.string() } )
export type showAsscociateDoctorsForType = z.infer<typeof showAsscociateDoctorsFor>
