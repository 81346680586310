import React from "react"
import AppGraph from "../../graph/Graph"

const TimeLine = () => {
	return (
		<React.Suspense>
			<AppGraph />
		</React.Suspense>
	)
}

export default TimeLine
